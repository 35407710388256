import React, { useEffect } from "react"
import { useGetProductHightlightQuery } from "../../../app/services/esaScraper/products"
import { ProductCard } from "../../../features/product_card/ProductCard"
import OwlCarousel from "react-owl-carousel"
import { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ProductModal } from "../../../features/modals/ProductModal"
import { useAppSelector } from "../../../app/hooks"
import { selectProductModal } from "../../../features/modals/modalsSlice"

export const ProductsHighlights: React.FC = () => {

  const productModal = useAppSelector(selectProductModal)
  const { data: products } = useGetProductHightlightQuery({ size: 20 })
  const owlCarouselRef = useRef<OwlCarousel>(null)

  const handleNext = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.next(650)
    }
  }

  const handlePrev = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.prev(650)
    }
  }

  useEffect(() => {
    console.log('products', products)
  }, [products])

  if (!products) return null

  return (

<>
    <div className="container">
      <div className="row pt-3 align-items-center">
        <div className="col-12 col-md-8 my-0 my-md-3 py-3  d-flex align-items-center justify-content-start">
          <h3 className="bolder-dark-title xs-font-33 font-47 my-1">
           Prodotti consigliati
          </h3>
        </div>
        <div className="col-12 col-md-4 my-3 py-3 align-items-center justify-content-end d-none d-md-flex">
          <span
            // id="eCommerceRecommended-prev"
            className="carousel-control dark-color mx-2"
            onClick={handlePrev}
          >
            {" "}
            <FontAwesomeIcon icon={["fas", "chevron-left"]} />{" "}
          </span>
          <span
            // id="eCommerceRecommended-next"
            className="carousel-control dark-color mx-2"
            onClick={handleNext}
          >
            {" "}
            <FontAwesomeIcon icon={["fas", "chevron-right"]} />{" "}
          </span>
        </div>
      </div>
      <div className="row pb-3">
        <div
          className="col-12 mb-3 pb-3"
        >
          <OwlCarousel
            ref={owlCarouselRef}
            className="owl-theme"
            dots={false}
            loop={true}
            dotsEach={3}
            autoplay={true}
            autoplayTimeout={4500}
            autoplayHoverPause={true}
            autoplaySpeed={650}
            nav={false}
            responsive={{
              0: {
                items: 1,
              },
              576: {
                items: 1,
              },
              768: {
                items: 2,
              },
              992: {
                items: 2,
              },
              1200: {
                items: 4,
              },
              1400: { items: 5 },
            }}
          >
            {Object.values(products.hits.hits).map((product) => (
            <ProductCard
              key={product._id}
              product={product._source}
              shop={undefined}
              hideCol
              hideSchema
              customItemClass="px-2 mx-1"
            />
          ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
    {productModal && <ProductModal />}
    </>
  )
}
