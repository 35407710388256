export const initFacebookSdk = () => {
  return new Promise<void>((resolve, reject) => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: import.meta.env.VITE_FACEBOOK_AUTH_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v22.0",
      })

      window.FB.AppEvents.logPageView()
      resolve()
    }
    ;(function (d, s, id) {
      var js: any,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode?.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  })
}

export const getFacebookLoginStatus = () => {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response: any) => {
      resolve(response)
    })
  })
}

export const fbLogin = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (response: any) => {
        resolve(response)
      },
      { scope: "public_profile,email" },
    )
  })
}

export const getProfileData = () => {
  return new Promise((resolve, reject) => {
    window.FB.api(
      `/me`,
      {
        fields: "id,first_name,last_name,email,link,gender,locale,picture",
      },
      (response: any) => {
        resolve(response)
      },
    )
  })
}
