import React, { useEffect, useState } from "react"
import {
  formatDateWithExtensedMonth,
  formatNumber,
  removeHtmlTags,
  toRatingText,
  toStringFromDateTime,
} from "../../../utils/stringUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setReportModal, setShareModal } from "../../modals/modalsSlice"
// import { ReviewSchema } from "../../schemas/ReviewSchema"
import { Shop } from "../../../models/base/shop.interface"
import { selectCurrentUser } from "../../auth/authSlice"
import { Warning } from "../../warning/Warning"
import starOrange from "../../../assets/images/star_orange.webp"
import { Fancybox } from "../../fancybox/Fancybox"
import overlayIcon from "../../../assets/icons/image_overlay_icon.svg"
import { getUserAvatar } from "../../../utils/userUtils"
import avatarPlaceholder from "../../../assets/images/avatar_placeh.png"
import { SimpleUser } from "../../../models/base/simpleUser.interface"
import {
  Review,
  ReviewImage,
  ReviewVideo,
} from "../../../models/base/review.interface"
import { getVideoThumbnail, parseReviewVideo } from "../../../utils/videoUtils"
import {
  getReviewImageUrl,
  getShowDetailRatings,
  isReportable,
} from "../../../utils/reviewUtils"
import { tryToParse } from "../../../utils/objectsUtils"
import { useSendLikeMutation } from "../../../app/services/base/reviews"
import { addToast } from "../../toasts/toastsSlice"
import imagePlaceholder from "../../../assets/images/thumb_placeholder.webp"
import { Helmet } from "react-helmet"

interface ReviewContainerProps {
  shop: Shop
  review: Review
  simpleUser: SimpleUser
  customClass?: string
  disableReviewLink?: boolean
}

const decodeText = (text: string) => {
  try {
    // Decoding using decodeURIComponent and escape
    return decodeURIComponent(escape(text))
  } catch (e) {
    // console.error("Failed to decode text:", e)
    return text
  }
}

export const ReviewContainer: React.FC<ReviewContainerProps> = ({
  shop,
  review,
  simpleUser,
  customClass,
  disableReviewLink,
}) => {
  const user = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const [sendLike] = useSendLikeMutation()
  const [reviewImages, setReviewImages] = useState<ReviewImage>()
  const [reviewVideo, setReviewVideo] = useState<ReviewVideo>()
  const [detailsRating, setDetailsRating] = useState<any[]>()
  const userName = simpleUser.name || simpleUser.surname || "Utente anonimo"
  const [showDetailRating, setShowDetailRating] = useState<boolean>(false)

  const [isLiked, setIsLiked] = useState<boolean | undefined>(review.liked)

  const title = `${simpleUser.name} ha recensito ${
    shop.domain
  } votando ${formatNumber(
    Number(review.overall_rating),
    1,
  )} e valutandolo ${toRatingText(
    Number(review.overall_rating),
  )}. Leggi tutta la recensione.`

  let report = user
    ? review.reports?.find((r) => r.consumer_id === user?.id)
    : undefined

  const [thumbnailUrl, setThumbnailUrl] = useState("")

  async function handleLike() {
    if (!isLiked) {
      try {
        const response = await sendLike({
          review_id: review.id,
        }).unwrap()
        if (!response.success) throw new Error()
      } catch (error) {
        dispatch(
          addToast({
            message: "Oops! Qualcosa è andato storto.",
            type: "danger",
          }),
        )
      }
    }
    setIsLiked(!isLiked)
  }

  const checkDetailRatings = async (review: Review) => {

    if(review.rating_info == null) {
      return await tryToParse(review.details_rating)
    }

    if(review.rating_info && Object.keys(review.rating_info).length == 0) {
      return await tryToParse(review.details_rating)
    }
    
    let details: Record<string, number> = {}
    let requiredRatings = review.rating_info.required_ratings
    let rawRatings = review.rating_info.raw_ratings
    let ratingMap = review.rating_info.rating_map

    //if string json parse
    if(typeof requiredRatings === "string") {
      requiredRatings = JSON.parse(requiredRatings)
    }
    if(typeof rawRatings === "string") {
      rawRatings = JSON.parse(rawRatings)
    }
    if(typeof ratingMap === "string") {
      ratingMap = JSON.parse(ratingMap)
    }

    // remove value 0
    rawRatings = Object.fromEntries(
      Object.entries(rawRatings).filter(([_, value]) => value !== 0)
    )
    
    if (rawRatings && Object.keys(rawRatings).length >= Object.keys(requiredRatings).length) {
      Object.keys(rawRatings).forEach((ratingKey: string) => {
        const mappedName = ratingMap[ratingKey] || ratingKey
        details[mappedName] = rawRatings[ratingKey]
      })
    }
    
    return details
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = reviewVideo
          ? await getVideoThumbnail(reviewVideo.videoId, reviewVideo.url)
          : ""
        setThumbnailUrl(url)
      } catch (error) {
        // console.error("Error fetching video thumbnail:", error)
      }
    }

    fetchData()
  }, [reviewVideo])

  useEffect(() => {
    const parseData = async () => {
      if (review && review.images) {
        const parsedData = await tryToParse(review.images)
        setReviewImages(parsedData)
      }

      if (review && review.video_url) {
        const parsedData = await parseReviewVideo(review.video_url)
        setReviewVideo(parsedData)
      }

      if (review) {
        const detailRatings = await checkDetailRatings(review)
        setDetailsRating(detailRatings)
        setShowDetailRating(getShowDetailRatings(detailRatings))
      }
    }

    if (review) {
      parseData()
    }
  }, [review])

  return (
    <>
      {/* <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={title} />
        <meta
          property="og:image"
          content={import.meta.env.VITE_CDN_BASE_URL + "/assets/img/share.png"}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content={import.meta.env.VITE_CDN_BASE_URL + "/assets/img/share.png"}
        />
      </Helmet> */}
      {/* <ReviewSchema shop={shop} review={review} simpleUser={simpleUser} /> */}
      <div
        className={`list-group-item p-0 py-4 py-lg-4 ${
          customClass ? customClass : ""
        }`}
      >
        <div className="d-flex w-100 justify-content-between align-items-start mb-4">
          <div className="d-flex flex-column flex-lg-row gap-4">
            <div className="d-flex align-items-center">
              <div
                className="profile-photo position-relative rounded-circle me-3"
                style={{ backgroundColor: "#f09390" }}
              >
                {review.user_id ? (
                  <Link
                    to={`/it/utenti/${review.user_id}/${
                      simpleUser.name || simpleUser.surname
                    }`}
                  >
                    <img
                      src={getUserAvatar(review.user_id)}
                      className="full-rounded"
                      loading="lazy"
                      width="60"
                      height="60"
                      alt={`Recensione e-commerce ${shop.domain} di ${
                        simpleUser.name || simpleUser.surname
                      }`}
                      onError={(event) =>
                        (event.currentTarget.src = avatarPlaceholder)
                      }
                    />
                  </Link>
                ) : (
                  <img
                    src={avatarPlaceholder}
                    className="full-rounded"
                    loading="lazy"
                    width="60"
                    height="60"
                    alt={`Recensione e-commerce ${shop.domain} di ${
                      simpleUser.name || simpleUser.surname
                    }`}
                  />
                )}
              </div>
              <div>
                {review.user_id ? (
                  <Link
                    to={`/it/utenti/${review.user_id}/${userName}`}
                    className="bolder-dark-title font-23 m-0 mb-2 text-decoration-none"
                    title={userName}
                  >
                    <span className="line-clamp">{userName}</span>
                  </Link>
                ) : (
                  <h5 className="bolder-dark-title font-23 m-0 mb-2">
                    <span className="line-clamp">{userName}</span>
                  </h5>
                )}

                {(simpleUser.reviews_number || 0) > 0 && (
                  <p className="lightgray-txt m-0">
                    {simpleUser.reviews_number}{" "}
                    {simpleUser.reviews_number == 1
                      ? "recensione"
                      : "recensioni"}
                  </p>
                )}
              </div>
            </div>

            {simpleUser.career_level && (
              <div className="d-flex mt-lg-2">
                <div className="d-flex new-user align-items-center">
                  <FontAwesomeIcon
                    icon={["fas", "award"]}
                    className="font-16"
                  />
                  <div>{simpleUser.career_level}</div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center gap-3">
            <small className="lightgray-txt me-4 d-none d-lg-block">
              {formatDateWithExtensedMonth(
                review.publication_date || review.insert_date,
              )}
            </small>
            <a
              className="share-modal-button cursor-pointer"
              onClick={() => dispatch(setShareModal(review))}
            >
              <FontAwesomeIcon
                icon={["fas", "share-nodes"]}
                className="thin-icon orange-color font-24"
              />
            </a>
            {/* TODO Nascondi bandierina quando submit */}
            {isReportable(review, user?.id) && (
              <a
                className="cursor-pointer"
                onClick={() =>
                  dispatch(setReportModal({ review: review, eshop: shop }))
                }
              >
                <FontAwesomeIcon
                  icon={["fas", "flag"]}
                  className="thin-icon orange-color font-24"
                />
              </a>
            )}
          </div>
        </div>
        {report && (
          <Warning
            title={
              report.status === 0 || report.status === 3 ? (
                <span className="dark-color font-16">
                  Abbiamo ricevuto la tua segnalazione il{" "}
                  <span className="font-bolder">
                    {" "}
                    {toStringFromDateTime(report.insert_date)}
                  </span>{" "}
                  e il nostro staff la sta valutando.
                </span>
              ) : report.status === 1 ? (
                <span className="dark-color font-16">
                  Abbiamo ricevuto la tua segnalazione e abbiamo ritenuto che la
                  recensione sia da ritenersi conforme alle nostre policy,
                  pertanto continuerà ad essere visibile su eShoppingAdvisor.
                </span>
              ) : (
                <span className="dark-color font-16">
                  Abbiamo ricevuto la tua segnalazione e abbiamo ritenuto che la
                  recensione non sia da ritenersi conforme alle nostre policy,
                  pertanto è stata sospesa e non sarà più visibile su
                  eShoppingAdvisor.
                </span>
              )
            }
            message={
              <>
                <h3 className="font-16 orange-color font-bolder">
                  {" "}
                  Motivazione{" "}
                </h3>{" "}
                <span className="dark-color font-16 font-bolder">
                  {report.reason}
                </span>
              </>
            }
          />
        )}
        {review.is_prod_review == 1 ? (
          <>
            <div className="d-flex flex-column flex-lg-row mb-3 mb-lg-4">
              {review.prod_review?.img_prod && (
                <div className="mb-3 mb-lg-0 me-lg-3">
                  <img
                    src={review.prod_review.img_prod}
                    className="product-review-photo"
                    alt={decodeText(review.title)}
                  />
                </div>
              )}
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between mb-2 mb-lg-1">
                  <div
                    className="font-semi-bolder"
                    style={{ color: "#e6610e" }}
                  >
                    <img
                      src={starOrange}
                      alt="star"
                      width="24px"
                      height="23px"
                      style={{ marginBottom: "4px" }}
                    />
                    <span className="font-semi-bolder font-16 simple-orange-txt">
                      {formatNumber(Number(review.overall_rating), 1)}
                    </span>
                    <span className="dark-color font-semi-bolder mx-1">
                      {toRatingText(Number(review.overall_rating))}
                    </span>
                  </div>
                  <small className="lightgray-txt d-lg-none">
                    {" "}
                    {review.insert_date
                      ? formatDateWithExtensedMonth(review.insert_date)
                      : ""}{" "}
                  </small>
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-between">
                  <div className="mb-0 lg-w-75">
                    <h4 className="bolder-dark-title m-0 mb-lg-1 font-23 line-clamp">
                      {decodeText(review.title)}
                    </h4>
                  </div>
                  {review.certified == 2 && (
                    <p className="d-lg-block d-flex gap-1 lightgray-txt font-semi-bolder m-0">
                      <span>
                        <FontAwesomeIcon icon={["fas", "check"]} />
                      </span>{" "}
                      Verificata
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex mb-3">
              <p className="lightgray-txt font-16 m-0 break-word">
                {decodeText(review.description)}
              </p>
            </div>
            <div className="d-flex">
              <p className="d-lg-none d-flex gap-1 lightgray-txt font-semi-bolder m-0 mb-3">
                <span>
                  <FontAwesomeIcon icon={["fas", "check"]} />
                </span>{" "}
                Verificata
              </p>
            </div>
            {review.source && (
              <div className="d-flex flex-lg-column align-items-lg-end gap-2 gap-lg-0 mb-3">
                <p className="lightgray-txt m-0">
                  <span>
                    <FontAwesomeIcon icon={["fas", "file-import"]} />
                  </span>
                  Importato
                </p>
                <p className="lightgray-txt m-0">
                  <span className="line-clamp break-all" title={review.source}>
                    Fonte
                    {review.source}
                  </span>
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between mb-2 mb-lg-1">
              <div className="font-semi-bolder" style={{ color: "#e6610e" }}>
                <img
                  src={starOrange}
                  alt="star"
                  width="24px"
                  height="23px"
                  style={{ marginBottom: "4px" }}
                />
                <span className="font-semi-bolder font-16 simple-orange-txt">
                  {" "}
                  {formatNumber(Number(review.overall_rating), 1)}{" "}
                </span>
                <span className="dark-color font-semi-bolder mx-1">
                  {" "}
                  {toRatingText(Number(review.overall_rating))}{" "}
                </span>
              </div>
              <small className="lightgray-txt d-lg-none">
                {" "}
                {review.insert_date
                  ? formatDateWithExtensedMonth(review.insert_date)
                  : ""}{" "}
              </small>
            </div>

            <div className="d-flex flex-column justify-content-between">
              <div className="col-12 mb-3 mb-lg-4">
                <div className="d-flex flex-column flex-lg-row justify-content-between">
                  <div className="mb-0 lg-w-75">
                    {disableReviewLink ? (
                      <span className="bolder-dark-title m-0 mb-2 mb-lg-1 font-23 text-decoration-none line-clamp">
                        {decodeText(review.title)}
                      </span>
                    ) : (
                      <Link
                        to={`/it/reviews/${review.id}`}
                        className="bolder-dark-title m-0 mb-2 mb-lg-1 font-23 text-decoration-none line-clamp"
                      >
                        {decodeText(review.title)}
                      </Link>
                    )}
                  </div>
                  {review.certified == 2 && (
                    <p className="d-lg-block d-none gap-1 lightgray-txt font-semi-bolder m-0">
                      <span>
                        <FontAwesomeIcon icon={["fas", "check"]} />
                      </span>{" "}
                      Verificata
                    </p>
                  )}
                </div>
                <p className="lightgray-txt font-16 m-0 break-word">
                  {decodeText(review.description)}
                </p>
              </div>
              {review.certified == 2 && (
                <div className="d-flex">
                  <p className="d-lg-none d-flex gap-1 lightgray-txt font-semi-bolder m-0 mb-3">
                    <span>
                      <FontAwesomeIcon icon={["fas", "check"]} />
                    </span>{" "}
                    Verificata
                  </p>
                </div>
              )}
              {review.source && (
                <div className="col-12 d-flex gap-2">
                  <p className="lightgray-txt m-0">
                    <span>
                      <FontAwesomeIcon icon={["fas", "file-import"]} />
                    </span>
                    Importato
                  </p>
                  <p className="lightgray-txt mb-3">
                    <span
                      className="line-clamp break-all"
                      title={review.source}
                    >
                      Fonte: {review.source}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </>
        )}

        {reviewImages && Object.keys(reviewImages).length > 0 && (
          <Fancybox
            options={{
              Carousel: {
                infinite: false,
              },
              Toolbar: {
                display: {
                  left: [],
                  middle: [],
                  right: ["slideshow", "fullscreen", "thumbs", "close"],
                },
              },
            }}
          >
            <div className="d-flex flex-row flex-wrap mb-3 align-items-center">
              {Object.entries(reviewImages).map(([key, value]) => {
                return (
                  <a
                    key={key}
                    className="d-flex justify-content-center align-items-center width-150 height-150 image-modal-button me-3 mb-1 overflow-hidden"
                    data-src={getReviewImageUrl(
                      value.name,
                      review.id,
                      review.is_prod_review,
                    )}
                    data-fancybox="review-gallery"
                  >
                    <img
                      className="review-img-attachment h-auto"
                      src={getReviewImageUrl(
                        value.name,
                        review.id,
                        review.is_prod_review,
                      )}
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = imagePlaceholder)}
                    />
                    <div className="overlay-middle">
                      <img src={overlayIcon} loading="lazy" />
                      <span className="font-12 white-color">Visualizza</span>
                    </div>
                  </a>
                )
              })}
            </div>
          </Fancybox>
        )}

        {reviewVideo && reviewVideo.stato == 1 && (
          <div className="video-review-container">
            <div className="d-flex flex-row flex-wrap mb-3">
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                  Toolbar: {
                    display: {
                      left: [],
                      middle: [],
                      right: ["slideshow", "fullscreen", "thumbs", "close"],
                    },
                  },
                }}
              >
                <a
                  data-src={reviewVideo.url}
                  data-fancybox="review-gallery-video"
                >
                  <img src={thumbnailUrl} className="height-150" />
                </a>
              </Fancybox>
            </div>
          </div>
        )}

        {review.is_prod_review == 0 &&
          !review.source &&
          detailsRating &&
          showDetailRating && (
            <ul className="list-unstyled d-flex flex-column flex-lg-row justify-content-lg-between m-0 mb-2 mb-lg-3">
              <div className="d-flex flex-column">
                {Object.entries(detailsRating)
                  .slice(0, 3)
                  .map(([key, value]) => {
                    if (value > 0) {
                      return (
                        <li
                          key={key}
                          className="dark-color font-semi-bolder mb-3"
                        >
                          <span className="font-16 simple-orange-txt font-semi-bolder me-2">
                            {formatNumber(value, 1)}
                          </span>
                          {key.replace(/\\\//g, "/")}
                        </li>
                      )
                    }
                    return null
                  })}
              </div>
              <div className="d-flex flex-column me-lg-5">
                {Object.entries(detailsRating)
                  .slice(3)
                  .map(([key, value]) => {
                    if (value > 0) {
                      return (
                        <li
                          key={key}
                          className="dark-color font-semi-bolder mb-3"
                        >
                          <span className="font-16 simple-orange-txt font-semi-bolder me-2">
                            {formatNumber(value, 1)}
                          </span>
                          {key.replace(/\\\//g, "/")}
                        </li>
                      )
                    }
                    return null
                  })}
              </div>
            </ul>
          )}

        {review.replies && (
          <>
            {review.replies.map(({ reply, reply_date }) => (
              <div
                key={reply}
                className="review-response p-3 mb-2"
                style={{ borderLeft: "4px solid var(--esaCustom-blue)" }}
              >
                <div className="d-none d-lg-flex justify-content-between">
                  <h6 className="font-semi-bolder dark-color font-16 m-0 mb-1 line-clamp">
                    Risposta di {shop.domain}
                  </h6>
                  <div className="lightgray-txt">
                    {" "}
                    {formatDateWithExtensedMonth(reply_date)}{" "}
                  </div>
                </div>
                <div className="d-block d-lg-none">
                  <h6 className="font-semi-bolder dark-color font-16 m-0 mb-1 line-clamp line-clamp-2">
                    Risposta di {shop.domain}
                  </h6>
                  <div className="lightgray-txt text-end">
                    {" "}
                    {formatDateWithExtensedMonth(reply_date)}{" "}
                  </div>
                </div>
                <p className="lightgray-txt m-0">
                  {" "}
                  {removeHtmlTags(decodeText(reply))}{" "}
                </p>
              </div>
            ))}
          </>
        )}

        <div className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-start align-items-lg-center">
          {review.replies?.length == 0 && review.status == 1 ? (
            <div className="d-flex flex-column flex-lg-row order-1 order-lg-2 mb-3 mb-lg-0">
              <p className="font-lighter lightgray-txt font-14 m-0">
                Sei {shop.domain} e vuoi rispondere?{" "}
                <span>
                  <Link
                    to={
                      shop.plan == "ibrido"
                        ? `https://eshoppingadvisor.com/aziende/scopri-rating/?dominio=${shop.domain}`
                        : `https://business.eshoppingadvisor.com/areariservata/login`
                    }
                    target="_blank"
                    rel="nofollow"
                    className="font-14 simple-orange-txt text-decoration-none"
                  >
                    Clicca qui
                  </Link>
                </span>
              </p>
            </div>
          ) : (
            <></>
          )}

          <a
            // to={`/it/reviews-like/${review.id}/1`}
            className={`review-reaction btn btn-review-up font-semi-bolder order-2 order-lg-1 ${
              isLiked ? "active" : ""
            }`}
            rel="nofollow"
            onClick={handleLike}
          >
            <span className="likeTxt">
              {isLiked ? "Mi è stata utile" : "Ti è stata utile?"}
            </span>
            <FontAwesomeIcon
              icon={["far", "thumbs-up"]}
              className="dark-color font-18"
              style={{ marginLeft: "20px" }}
            />
          </a>
        </div>
      </div>
    </>
  )
}
