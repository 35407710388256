import { Review } from "../../../models/base/review.interface"
import { baseApi } from "./baseApi"
import { SendReviewFormData } from "../../../pages/send_review/sendReviewSlice"
import { ListResponse } from "../../../models/base/listResponse.interface"
import { ReviewShopUserUnion } from "../../../models/base/reviewShopUserUnion.interface"
import { ReviewsStats } from "../../../models/base/reviewsStats.interface"
import { ReviewUserUnion } from "../../../models/base/reviewUserUnion.interface"
import { ReviewShopUnion } from "../../../models/base/reviewShopUnion.interface"

export const reviewsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getReview: build.query<ReviewShopUserUnion, string>({
      query: (reviewId) => ({
        url: `reviews/frontend/fetch-detail-review/${reviewId}`,
      }),
      providesTags: (result, error, reviewId) => [
        { type: "Reviews", reviewId },
      ],
    }),
    // MAPPATO
    getReviewForAuthUser: build.query<ReviewShopUserUnion, string>({
      query: (reviewId) => ({
        url: `reviews/frontend/fetch-detail-review-auth/${reviewId}`,
      }),
      providesTags: (result, error, reviewId) => [
        { type: "Reviews", reviewId },
      ],
    }),
    // MAPPATO
    getReviews: build.query<ListResponse<ReviewUserUnion>, string>({
      query: (eshopId) => {
        return {
          url: `reviews/frontend/fetch-eshop-reviews/${eshopId}`,
        }
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ review }) => ({
                type: "Reviews" as const,
                id: review.id,
              })),
              { type: "Reviews", id: "LIST" },
            ]
          : [{ type: "Reviews", id: "LIST" }],
    }),
    // MAPPATO
    getLatestReviews: build.query<ListResponse<ReviewShopUserUnion>, void>({
      query: () => {
        return {
          url: `reviews/frontend/fetch-reviews`,
        }
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ review }) => ({
                type: "Reviews" as const,
                id: review.id,
              })),
              { type: "Reviews", id: "LIST" },
            ]
          : [{ type: "Reviews", id: "LIST" }],
    }),
    // MAPPATO
    getUserReviews: build.query<
      ListResponse<ReviewShopUnion>,
      {
        user_id: number
        title?: string
        domain?: string
        since?: string
        until?: string
        status?: number | number[]
        page: number
        perPage: number
      }
    >({
      query: ({
        user_id,
        domain,
        title,
        since,
        until,
        status,
        page,
        perPage,
      }) => ({
        url: `reviews/frontend/fetch-user-reviews/${user_id}`,
        params: {
          title,
          domain,
          since,
          until,
          status,
          page,
          "per-page": perPage,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ review }) => ({
                type: "Reviews" as const,
                id: review.id,
              })),
              { type: "Reviews", id: "LIST" },
            ]
          : [{ type: "Reviews", id: "LIST" }],
    }),
    // MAPPATO
    getReviewsStats: build.query<
      ReviewsStats,
      { eshopId: number; since?: string; until?: string }
    >({
      query: ({ eshopId, since, until }) => ({
        url: `reviews/frontend/fetch-review-stats/${eshopId}`,
        params: {
          since,
          until,
        },
      }),
      providesTags: (result, error, reviewId) => [
        { type: "Reviews", reviewId },
      ],
    }),
    // MAPPATO
    createReview: build.mutation<
      Review,
      {
        e: number
        domain?: string
        userId?: number
        v?: string
        body: SendReviewFormData,
        sendType?: string
      }
    >({
      query: ({ e, domain, userId, v, body, sendType }) => {
        return {
          url: `reviews/frontend/insert-review`,
          method: "POST",
          body: {
            eshop_id: e,
            user_id: userId,
            title: body.shop.title,
            description: body.shop.description,
            rating: body.shop.scores,
            video_url: body.shop.videoUrl,
            confirmedProducts: body.confirmedProducts,
            order_token: v,
            is_anonymous: body.shop.isAnonymous,
            domain,
            sendType: sendType,
          },
        }
      },
      invalidatesTags: [{ type: "Reviews" }],
    }),
     createProductReview: build.mutation<
      { success?: string },
      { 
        score: string; 
        description: string,
        order_id: string,
        prod_id: string,
      }
      >({
        query: (body) => {
          return {
            url: `reviews/frontend/insert-product-review`,
            method: "POST",
            body: {
              score: body.score, 
              description: body.description,
              order_id: body.order_id,
              prod_id: body.prod_id,
            },
          }
        },
        invalidatesTags: [{ type: "Invitations" }],
      }),
    // MAPPATO
    updateReview: build.mutation<
      { success?: string },
      {
        id: number
        title: string
        description: string
        rating: Record<string, number>
      }
    >({
      query: ({ id, title, description, rating }) => {
        return {
          url: `reviews/frontend/update-review/${id}`,
          method: "PUT",
          body: { title, description, rating },
        }
      },
      invalidatesTags: (response) => [{ type: "Reviews" }],
    }),
    // MAPPATO
    deleteReview: build.mutation<{ success?: string }, number>({
      query: (id) => {
        return {
          url: `reviews/frontend/delete-review/${id}`,
          method: "DELETE",
        }
      },
      invalidatesTags: (response) => [{ type: "Reviews" }],
    }),
    // MAPPATO
    deletePendingUpdate: build.mutation<{ success?: string }, number>({
      query: (reviewId) => {
        return {
          url: `reviews/frontend/delete-update-review/${reviewId}`,
          method: "DELETE",
        }
      },
      invalidatesTags: (response) => [{ type: "Reviews" }],
    }),
    // MAPPATO
    uploadImages: build.mutation<{ id: string }, FormData>({
      query: (body) => ({
        url: `reviews/frontend/upload-images`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Reviews" }],
    }),
    // MAPPATO
    uploadReceipt: build.mutation<{ success: string }, FormData>({
      query: (body) => ({
        url: `reviews/frontend/upload-receipt`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Reviews" }],
    }),
    // MAPPATO
    sendReport: build.mutation<
      {
        review_id: number
        consumer_id: number
        reason: string
        id: number
      },
      { reviewId: number; reason: string }
    >({
      query: ({ reviewId, reason }) => {
        return {
          url: `reviews/frontend/report-review`,
          method: "POST",
          body: { review_id: reviewId, reason },
        }
      },
      invalidatesTags: [{ type: "Reviews" }],
    }),
    // MAPPATO
    sendLike: build.mutation<{ success?: string }, { review_id: number }>({
      query: ({ review_id }) => {
        return {
          url: `reviews/frontend/like-review`,
          method: "POST",
          body: { review_id },
        }
      },
      invalidatesTags: [{ type: "Reviews" }],
    }),
    // MAPPATO
    uploadVideo: build.mutation<{ id: string }, FormData>({
      query: (body) => ({
        url: `reviews/frontend/upload-video`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Reviews" }],
    }),
     // MAPPATO
      getProductReview: build.query<ReviewShopUserUnion, string>({
      query: (reviewId) => ({
        url: `reviews/frontend/fetch-detail-review/${reviewId}?is_prod_review=true`,
      }),
      providesTags: (result, error, reviewId) => [
        { type: "Reviews", reviewId },
      ],
    }),
    // MAPPATO
    getProductReviewForAuthUser: build.query<ReviewShopUserUnion, string>({
      query: (reviewId) => ({
        url: `reviews/frontend/fetch-detail-review-auth/${reviewId}/prodReview/true`,
      }),
      providesTags: (result, error, reviewId) => [
        { type: "Reviews", reviewId },
      ],
    }),
    // MAPPATO
    getUserProductReviews: build.query<
      ListResponse<ReviewShopUnion>,
      {
        user_id: number
        title?: string
        domain?: string
        since?: string
        until?: string
        status?: number | number[]
        page: number
        perPage: number
      }
    >({
      query: ({
        user_id,
        domain,
        title,
        since,
        until,
        status,
        page,
        perPage,
      }) => ({
        url: `reviews/frontend/fetch-user-product-reviews/${user_id}`,
        params: {
          title,
          domain,
          since,
          until,
          status,
          page,
          "per-page": perPage,
        },
      }),
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ review }) => ({
                type: "Reviews" as const,
                id: review.id,
              })),
              { type: "Reviews", id: "LIST" },
            ]
          : [{ type: "Reviews", id: "LIST" }],
    }),
     // MAPPATO
     updateProductReview: build.mutation<
     { success?: string },
     {
       id: number
       title: string
       description: string
       rating: Record<string, number>
     }
   >({
     query: ({ id, title, description, rating }) => {
       return {
         url: `reviews/frontend/update-review/${id}?is_prod_review=true`,
         method: "PUT",
         body: { title, description, rating },
       }
     },
     invalidatesTags: (response) => [{ type: "Reviews" }],
   }),
   // MAPPATO
   deletePendingProductUpdate: build.mutation<{ success?: string }, number>({
    query: (reviewId) => {
      return {
        url: `reviews/frontend/delete-update-review/${reviewId}?is_prod_review=true`,
        method: "DELETE",
      }
    },
    invalidatesTags: (response) => [{ type: "Reviews" }],
    }),
    // MAPPATO
    deleteProductReview: build.mutation<{ success?: string }, number>({
      query: (id) => {
        return {
          url: `reviews/frontend/delete-review/${id}?is_prod_review=true`,
          method: "DELETE",
        }
      },
      invalidatesTags: (response) => [{ type: "Reviews" }],
    }),
    // MAPPATO
    updateReviewPublic: build.mutation<
    { success?: string },
    {
      id: number
      title: string
      description: string
      rating: Record<string, number>
      code: string
    }
  >({
    query: ({ id, title, description, rating, code }) => {
      return {
        url: `reviews/frontend/update-review-public/${id}/${code}`,
        method: "PUT",
        body: { title, description, rating },
      }
    },
    invalidatesTags: (response) => [{ type: "Reviews" }],
  }),
  // MAPPATO
  deleteReviewPublic: build.mutation<{ success?: string }, {id: number, code: string}>({
    query: ({id, code}) => {
      return {
        url: `reviews/frontend/delete-review-public/${id}/${code}`,
        method: "DELETE",
      }
    },
    invalidatesTags: (response) => [{ type: "Reviews" }],
  }),
    // MAPPATO
    updateProductReviewPublic: build.mutation<
    { success?: string },
    {
      id: number
      title: string
      description: string
      rating: Record<string, number>
      code: string
    }
  >({
    query: ({ id, title, description, rating, code }) => {
      return {
        url: `reviews/frontend/update-review-public/${id}/${code}?is_prod_review=true`,
        method: "PUT",
        body: { title, description, rating },
      }
    },
    invalidatesTags: (response) => [{ type: "Reviews" }],
  }),
  // MAPPATO
  deleteProductReviewPublic: build.mutation<{ success?: string }, {id: number, code: string}>({
    query: ({id, code}) => {
      return {
        url: `reviews/frontend/delete-review-public/${id}/${code}?is_prod_review=true`,
        method: "DELETE",
      }
    },
    invalidatesTags: (response) => [{ type: "Reviews" }],
  }),
  // MAPPATO
  deletePendingUpdatePublic: build.mutation<{ success?: string }, {reviewId: number, code: string}>({
    query: ({reviewId,code}) => {
      return {
        url: `reviews/frontend/delete-update-review-public/${reviewId}/${code}`,
        method: "DELETE",
      }
    },
    invalidatesTags: (response) => [{ type: "Reviews" }],
  }),
  // MAPPATO
  deletePendingProductUpdatePublic: build.mutation<{ success?: string }, {reviewId: number, code: string}>({
    query: ({reviewId,code}) => {
      return {
        url: `reviews/frontend/delete-update-review-public/${reviewId}/${code}?is_prod_review=true`,
        method: "DELETE",
      }
    },
    invalidatesTags: (response) => [{ type: "Reviews" }],
  }),
  }),
})

export const {
  useGetReviewQuery,
  useGetReviewForAuthUserQuery,
  useGetReviewsQuery,
  useGetLatestReviewsQuery,
  useGetUserReviewsQuery,
  useGetReviewsStatsQuery,
  useCreateReviewMutation,
  useCreateProductReviewMutation,
  useUpdateReviewMutation,
  useDeleteReviewMutation,
  useUploadImagesMutation,
  useUploadReceiptMutation,
  useDeletePendingUpdateMutation,
  useSendReportMutation,
  useSendLikeMutation,
  useUploadVideoMutation,
  useGetProductReviewQuery,
  useGetProductReviewForAuthUserQuery,
  useGetUserProductReviewsQuery,
  useUpdateProductReviewMutation,
  useDeletePendingProductUpdateMutation,
  useDeleteProductReviewMutation,
  useUpdateReviewPublicMutation,
  useDeleteReviewPublicMutation,
  useUpdateProductReviewPublicMutation,
  useDeleteProductReviewPublicMutation,
  useDeletePendingUpdatePublicMutation,
  useDeletePendingProductUpdatePublicMutation,
  } = reviewsApi