import { SearchFilterOption } from "../../../models/base/searchFilter.interface"
import { EsaScraperResponse } from "../../../models/esaScraper/esaScraperResponse.interface"
import { buildElasticQuery } from "../../../utils/elasticSearchUtils"
import { esaScraperApi } from "./esaScraperApi"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { EsaScraperProduct } from "../../../models/esaScraper/esaScraperProduct.interface"
import { EsaScraperProductData } from "../../../models/esaScraper/esaScraperProductData.interface"

export const productsApi = esaScraperApi.injectEndpoints({
  endpoints: (build) => ({
    // --TODO: Build endpoint for product info
    getProductInfo: build.query<
    EsaScraperProductData,
      number
      >({
      query: (link_domain_id) => {
        return {
          url: `esasearch/products/${link_domain_id}`,
          method: "GET",
        }
      },
    }),
    // MAPPATO
    getSearchProducts: build.query<
      EsaScraperResponse<EsaScraperProduct>,
      {
        searchText?: string
        filters: Record<string, SearchFilterOption>
        searchAfter: number[] | undefined
      }
    >({
      query: ({ searchText, filters, searchAfter }) => {
        const elasticQueryWithoutAggregation = buildElasticQuery(
          "Products",
          filters,
          searchAfter,
        )
        return {
          url: "esasearch/products/search/",
          method: "POST",
          params: {
            query_string: searchText,
          },
          body: elasticQueryWithoutAggregation,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (
        currentCache,
        newItems,
        { arg, baseQueryMeta, fulfilledTimeStamp, requestId },
      ) => {
        if (arg.searchAfter != undefined) {
          return {
            ...newItems,
            hits: {
              ...newItems.hits,
              hits: [...currentCache.hits.hits, ...newItems.hits.hits],
            },
          }
        } else {
          return newItems
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    // MAPPATO
    getProductsAggregations: build.query<
      EsaScraperResponse<EsaScraperProduct>,
      {
        searchText?: string
        filters: Record<string, SearchFilterOption>
        searchAfter: number[] | undefined
      }
    >({
      async queryFn(
        { searchText, filters, searchAfter },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const elasticQueryForDomains = buildElasticQuery(
          "Products",
          filters,
          searchAfter,
          "domain",
        )

        const domainsAggregation = (await fetchWithBQ({
          url: "esasearch/products/search/",
          params: { query_string: searchText },
          method: "POST",
          body: elasticQueryForDomains,
        })) as any

        if (domainsAggregation.error)
          return { error: domainsAggregation.error as FetchBaseQueryError }

        const elasticQueryForBrands = buildElasticQuery(
          "Products",
          filters,
          searchAfter,
          "brand",
        )

        const brandsAggregation = (await fetchWithBQ({
          url: "esasearch/products/search/",
          params: { query_string: searchText },
          method: "POST",
          body: elasticQueryForBrands,
        })) as any

        // Important: this call return ever hits equals to []
        return domainsAggregation.data && brandsAggregation.data
          ? {
              data: {
                ...domainsAggregation.data,
                aggregations: {
                  ...domainsAggregation.data.aggregations,
                  ...brandsAggregation.data.aggregations.brands_aggregation,
                },
              } as EsaScraperResponse<EsaScraperProduct>,
            }
          : { error: brandsAggregation.error as FetchBaseQueryError }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (
        currentCache,
        newItems,
        { arg, baseQueryMeta, fulfilledTimeStamp, requestId },
      ) => {
        if (arg.searchAfter != undefined) {
          return {
            ...newItems,
            hits: {
              ...newItems.hits,
              hits: [...currentCache.hits.hits, ...newItems.hits.hits],
            },
          }
        } else {
          return newItems
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    // MAPPATO
    getSuggestedProducts: build.query<
      EsaScraperResponse<EsaScraperProduct>,
      {
        searchText: string
        size?: number
      }
    >({
      query: ({ searchText, size = 6 }) => {
        return {
          url: "esasearch/products/suggester/",
          params: {
            query_string: searchText,
            size,
          },
          method: "GET",
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.hits.hits.map(({ _id }) => ({
                type: "Products" as const,
                _id,
              })),
              { type: "Products", id: "LIST" },
            ]
          : [{ type: "Products", id: "LIST" }],
    }),
    // MAPPATO
    getProductData: build.query<
      EsaScraperProductData,
      { link_domain_id: string; query_string?: string }
    >({
      query: ({ link_domain_id, query_string }) => {
        return {
          url: `esasearch/products/data/${link_domain_id}/`,
          params: {
            query_string,
          },
          method: "GET",
        }
      },
      providesTags: (result, error, link_domain_id) => [
        { type: "Products", link_domain_id },
      ],
    }),
    // MAPPATO
    getProductsRelated: build.query<
      EsaScraperResponse<EsaScraperProduct>,
      string
    >({
      query: (link_domain_id) => {
        return {
          url: `esasearch/products/related_products/${link_domain_id}/`,
          method: "GET",
        }
      },
      providesTags: (result, error, link_domain_id) => [
        { type: "Products", link_domain_id },
      ],
    }),
    // MAPPATO
    getProductHightlight: build.query<
      EsaScraperResponse<EsaScraperProduct>,
      {
        size?: number
      }
    >({
      query: ({ size = 20 }) => {
        return {
          url: "esasearch/products/search/",
          params: {},
          method: "POST",
          body: {
            "query": {
                "bool": {
                    "filter": [
                        {
                            "range": {
                                "plan": {
                                    "gte": 5
                                }
                            }
                        },
                        {
                            "exists": {
                                "field": "plan"
                            }
                        },
                        {
                            "range": {
                                "score": {
                                    "gte": 4,
                                    "lte": 5
                                }
                            }
                        },
                        {
                            "exists": {
                                "field": "score"
                            }
                        },
                        {
                          "term": {
                            adult_flag: false,
                          },
                        }
                    ]
                },
            },
            "size": size,
            "enrich_data": true,
        },
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.hits.hits.map(({ _id }) => ({
                type: "Products" as const,
                _id,
              })),
              { type: "Products", id: "LIST" },
            ]
          : [{ type: "Products", id: "LIST" }],
    }),
  }),
})

export const {
  useGetSearchProductsQuery,
  useGetProductsAggregationsQuery,
  useGetSuggestedProductsQuery,
  useGetProductDataQuery,
  useGetProductsRelatedQuery,
  useGetProductInfoQuery,
  useGetProductHightlightQuery,
} = productsApi
