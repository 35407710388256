import React, { Suspense, useState } from "react"
import { Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FilePond } from "react-filepond"
import { toast } from "react-toastify"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"
import { uploadVimeoVideo } from "../../utils/videoUtils"
import { Shop } from "../../models/base/shop.interface"
import {
  useUploadImagesMutation,
  useUploadVideoMutation,
} from "../../app/services/base/reviews"
import { remove } from "lodash"

interface StepThreeProps {
  review?: ReviewUserUnion
  isOpen?: boolean
  eshop?: Shop
}

interface FormDataState {
  images: File[]
  video: File | null
  videoUrl: string
}

export const MediaUpload: React.FC<StepThreeProps> = ({
  review,
  isOpen,
  eshop,
}) => {
  const [formData, setFormData] = useState<FormDataState>({
    images: [],
    video: null,
    videoUrl: "",
  })
  const [isMedia, setIsMedia] = useState<boolean>(isOpen || false)
  const [showImageUpload, setShowImageUpload] = useState<boolean>(true)
  const [showVideoUpload, setShowVideoUpload] = useState<boolean>(false)
  const [uploadImages] = useUploadImagesMutation()
  const [uploadVideo] = useUploadVideoMutation()

  const handleImageUpdate = (fileItems: any[]) => {
    // Replace the entire images array instead of appending
    const files = fileItems.map((fileItem) => fileItem.file)
    setFormData((prev) => ({
      ...prev,
      images: files, // This will completely replace the previous images array
    }))

    // Log for debugging
    console.log("Current files:", files.length)
  }

  const handleVideoUpdate = (fileItems: any[]) => {
    const videoFile = fileItems.length > 0 ? fileItems[0].file : null
    setFormData((prev) => ({
      ...prev,
      video: videoFile,
    }))
  }

  const handleImageUpload = async () => {
    if (review) {
      try {
        // Get all files from FilePond
        const currentFiles = (window as any).pond.getFiles()
        const unprocessedFiles = currentFiles.filter(
          (file: any) => !file.processed,
        )

        // Process each unprocessed file
        for (const file of unprocessedFiles) {
          const request = new FormData()
          request.append("review_id", review.review.id.toString())
          request.append("is_product", "false")
          request.append("images[]", file.file, file.filename)

          try {
            await uploadImages(request).unwrap()
            file.processed = true // Mark as processed
            toast.success(`Immagine ${file.filename} caricata`)
          } catch (error) {
            toast.error(`Errore caricamento ${file.filename}`)
          }
        }
      } catch (error) {
        console.error("Upload error:", error)
        toast.error("Errore caricamento generale")
      }
    }
  }

  const handleVideoUpload = async (error: any, file: any) => {
    if (error) {
      console.error("Error uploading video:", error)
      toast.error("Errore durante il caricamento del video")
      return
    }

    // Get all files from FilePond
    const video = file.file
    if (review && video) {
      try {
        const domainName = eshop?.domain
        const vimeoTitle = review.user
          ? `${review.user.name} recensisce ${domainName}`
          : `Recensione su ${domainName}`

        const videoUrl = await uploadVimeoVideo(video, vimeoTitle)
        if (videoUrl) {
          setFormData((prev) => ({
            ...prev,
            videoUrl,
          }))
          const request = new FormData()
          request.append("review_id", review.review.id.toString())
          request.append("is_product", "false")
          request.append("video_url", videoUrl)
          await uploadVideo(request).unwrap()
          toast.success("Video caricato con successo")
        }
      } catch (error) {
        console.error("Error uploading video:", error)
        toast.error("Errore durante il caricamento del video")
      }
    }
  }

  const handleRemoveImage = (file: any) => {
    //
  }

  return (
    <>
      <div>
        {!isMedia ? (
          <div
            id="uploadMediaShow"
            className="custom-file d-flex align-items-center justify-content-center w-100 mb-4 cursor-pointer"
            onClick={() => setIsMedia(!isMedia)}
          >
            <FontAwesomeIcon
              icon={["fas", "photo-film"]}
              className="me-1 font-23"
            />
            <span className="font-semi-bolder ms-2">Carica foto/video</span>
          </div>
        ) : null}

        {isMedia ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex flex-row align-items-center justify-content-start w-100 mb-2">
              <div id="media-row" className="w-100">
                <div className="col-12">
                  <span
                    className={`btn ${
                      showImageUpload
                        ? "btn-gradient-orange"
                        : "btn-outline-orange"
                    } me-2`}
                    onClick={() => {
                      setShowImageUpload(true)
                      setShowVideoUpload(false)
                    }}
                  >
                    <b> Foto </b>
                  </span>
                  <span
                    className={`btn ${
                      showVideoUpload
                        ? "btn-gradient-orange"
                        : "btn-outline-orange"
                    } me-2`}
                    onClick={() => {
                      setShowImageUpload(false)
                      setShowVideoUpload(true)
                    }}
                  >
                    <b> Video </b>
                  </span>
                </div>

                <div
                  className={`col-12 my-2 ${showImageUpload ? "" : "d-none"}`}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <FilePond
                      ref={(ref) => ((window as any).pond = ref)}
                      className={"custom-filepond"}
                      allowFileEncode={true}
                      allowImagePreview={true}
                      imagePreviewHeight={100}
                      allowFileTypeValidation={true}
                      acceptedFileTypes={[
                        "image/jpg",
                        "image/jpeg",
                        "image/png",
                      ]}
                      allowFileSizeValidation={true}
                      maxFileSize={"3MB"}
                      maxTotalFileSize={"15MB"}
                      allowMultiple={true}
                      maxFiles={5}
                      credits={undefined}
                      allowPaste={false}
                      storeAsFile={true}
                      labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                      onupdatefiles={handleImageUpdate}
                      onprocessfileabort={() => {
                        toast.error("Errore caricamento")
                      }}
                      onremovefile={(error, file) => {
                        if (!error) {
                          toast.success("Immagine rimossa")
                          handleRemoveImage(file)
                        }
                      }}
                      onaddfile={handleImageUpload}
                    />
                  </Suspense>
                  <span className="font-12 dark-color">
                    E' possibile caricare fino a 5 immagini
                  </span>
                </div>

                <div
                  className={`col-12 my-2 ${showVideoUpload ? "" : "d-none"}`}
                >
                  <div className="upload-video-area my-2">
                    {!formData.videoUrl && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <FilePond
                          ref={(ref) => {
                            ;(window as any).videoPond = ref
                            console.log("FilePond ref updated:", ref)
                          }}
                          allowFileEncode={true}
                          allowFileTypeValidation={true}
                          acceptedFileTypes={[
                            "video/mp4",
                            "video/mov",
                            "video/avi",
                            "video/flv",
                            "video/wmv",
                          ]}
                          allowFileSizeValidation={true}
                          maxFileSize={"250MB"}
                          maxTotalFileSize={"250MB"}
                          allowMultiple={false}
                          maxFiles={1}
                          credits={undefined}
                          allowPaste={false}
                          storeAsFile={true}
                          labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                          onupdatefiles={(fileItems) => {
                            console.log(
                              "onupdatefiles triggered with:",
                              fileItems,
                            )
                            handleVideoUpdate(fileItems)
                          }}
                          onaddfile={(error, file) => {
                            console.log("onaddfile triggered with:", {
                              error,
                              file,
                            })
                            handleVideoUpload(error, file)
                          }}
                          onprocessfileabort={() => {
                            console.log("onprocessfileabort triggered")
                            setFormData((prev) => ({
                              ...prev,
                              video: null,
                              videoUrl: "",
                            }))
                            toast.error("Caricamento video annullato")
                          }}
                          onremovefile={() => {
                            console.log("onremovefile triggered")
                            setFormData((prev) => ({
                              ...prev,
                              video: null,
                              videoUrl: "",
                            }))
                            toast.success("Video rimosso")
                          }}
                          beforeAddFile={(file) => {
                            console.log("beforeAddFile triggered with:", file)
                            const fileSizeInMB = file.fileSize / (1024 * 1024)
                            if (fileSizeInMB > 250) {
                              toast.error("Il file supera il limite di 250MB")
                              return false
                            }
                            return true
                          }}
                        />
                      </Suspense>
                    )}
                    <span className="font-12 dark-color mb-3">
                      Il video deve avere una durata massima di 1:30 minuti e un
                      peso sotto i 250MB{" "}
                      <span className="d-inline-block">
                        (.mp4, .mov, .avi, .flv, .wmv)
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
