import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { Shop } from "../../../models/base/shop.interface"
import { SuggestedShop } from "../../../models/base/suggestedShop.interface"
import { baseApi } from "./baseApi"
import { productsApi } from "../esaScraper/products"

export const shopsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getShopById: build.query<Shop, string>({
      query: (eShopId) => ({ url: `domains/frontend/get-domain/${eShopId}?exact=true` }),
      providesTags: (result, error, domain) => [{ type: "Shops", domain }],
    }),
    // MAPPATO
    getShops: build.query<
      Shop[],
      {
        domain?: string | number
        page?: number
        perPage?: number
      }
    >({
      query: ({ domain}) => {
        return {
          url: 'domains/frontend/get-domain/' + domain
        }
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Shops" as const, id })),
              { type: "Shops", id: "LIST" },
            ]
          : [{ type: "Shops", id: "LIST" }],
    }),
    // MAPPATO
    getSuggestedShops: build.query<SuggestedShop[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let suggestedShops = (await fetchWithBQ({
          url: "domains/frontend/suggested",
        })) as any

        if (suggestedShops.error)
          return { error: suggestedShops.error as FetchBaseQueryError }

        const productsByDomains = (await fetchWithBQ({
          url: `${
            import.meta.env.VITE_API_ESASCRAPER_BASE_URL
          }esasearch/products/search/`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${
              import.meta.env.VITE_API_ESASCRAPER_TOKEN
            }`,
          },
          body: JSON.stringify({
            query: {
              bool: {
                filter: [
                  {
                    terms: {
                      domain: suggestedShops.data.map(
                        (sugShop: SuggestedShop) => sugShop.domain,
                      ),
                    },
                  },
                ],
              },
            },
            domain_aggregation: true,
          }),
        })) as any

        suggestedShops.data = suggestedShops.data.map(
          (suggestedShop: SuggestedShop) => {
            const docCount =
              productsByDomains.data.aggregations.domains.buckets.find(
                (bucket: any) =>
                  bucket.top_domains_hits.hits.hits[0]._source.domain ==
                  suggestedShop.domain,
              )?.doc_count

            return { ...suggestedShop, nProducts: docCount ? docCount : 0 }
          },
        )

        return suggestedShops.data && productsByDomains.data
          ? suggestedShops
          : { error: productsByDomains.error as FetchBaseQueryError }
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Shops" as const, id })),
              { type: "Shops", id: "LIST" },
            ]
          : [{ type: "Shops", id: "LIST" }],
    }),
    getTopSuggestedShops: build.query<SuggestedShop[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let suggestedShops = (await fetchWithBQ({
          url: "domains/frontend/top-suggested",
        })) as any

        if (suggestedShops.error)
          return { error: suggestedShops.error as FetchBaseQueryError }

        return suggestedShops
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Shops" as const, id })),
              { type: "Shops", id: "LIST" },
            ]
          : [{ type: "Shops", id: "LIST" }],
    }),
  }),
})

export const {
  useGetShopByIdQuery,
  useGetShopsQuery,
  useGetSuggestedShopsQuery,
  useGetTopSuggestedShopsQuery,
} = shopsApi
