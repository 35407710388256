import React, { useRef } from "react"
import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.min.css"
import "owl.carousel/dist/assets/owl.theme.default.min.css"
import { SuggestedShopItem } from "./SuggestedShopItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useGetSuggestedShopsQuery } from "../../../app/services/base/shops"
import { useGetTopSuggestedShopsQuery } from "../../../app/services/base/shops"

interface SuggestedShopProps {
  topEshops: boolean
}

export const SuggestedShops: React.FC<SuggestedShopProps> = ({ topEshops }) => {
  const owlCarouselRef = useRef<OwlCarousel>(null)
  const topShopsQuery = useGetTopSuggestedShopsQuery()
  const suggestedShopsQuery = useGetSuggestedShopsQuery()

  const suggestedShops = topEshops
    ? topShopsQuery.data
    : suggestedShopsQuery.data

  const handleNext = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.next(650)
    }
  }

  const handlePrev = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.prev(650)
    }
  }

  if (!suggestedShops) {
    return <></>
  }

  return (
    <div className="container">
      <div className="row pt-3 align-items-center">
        <div className="col-12 col-md-8 my-0 my-md-3 py-3  d-flex align-items-center justify-content-start">
          <h3 className="bolder-dark-title xs-font-33 font-47 my-1">
            {topEshops ? "Top eCommerce" : "eCommerce recensiti"}
          </h3>
        </div>
        <div className="col-12 col-md-4 my-3 py-3 align-items-center justify-content-end d-none d-md-flex">
          <span
            // id="eCommerceRecommended-prev"
            className="carousel-control dark-color mx-2"
            onClick={handlePrev}
          >
            {" "}
            <FontAwesomeIcon icon={["fas", "chevron-left"]} />{" "}
          </span>
          <span
            // id="eCommerceRecommended-next"
            className="carousel-control dark-color mx-2"
            onClick={handleNext}
          >
            {" "}
            <FontAwesomeIcon icon={["fas", "chevron-right"]} />{" "}
          </span>
        </div>
      </div>
      <div className="row pb-3">
        <div
          // id="eshop-recommended-carousel"
          className="col-12 mb-3 pb-3"
        >
          <OwlCarousel
            // id="eCommerceRecommended-carousel"
            ref={owlCarouselRef}
            className="owl-theme"
            dots={false}
            loop={true}
            dotsEach={3}
            autoplay={true}
            autoplayTimeout={4500}
            autoplayHoverPause={true}
            autoplaySpeed={650}
            nav={false}
            responsive={{
              0: {
                items: 1,
              },
              576: {
                items: 1,
              },
              768: {
                items: 2,
              },
              992: {
                items: 2,
              },
              1200: {
                items: 4,
              },
              1400: { items: 5 },
            }}
          >
            {suggestedShops.map((suggestedShop) => (
              <SuggestedShopItem
                key={suggestedShop.id}
                suggestedShop={suggestedShop}
              />
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}
