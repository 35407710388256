import React, { useEffect } from "react"
import volpeWink from "../../assets/images/volpe-wink.png"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../../features/auth/authSlice"
import {
  ActualFileObject,
  ProcessServerChunkTransferOptions,
  ProgressServerConfigFunction,
} from "filepond"
//import * as FilePondConfig from "filepond"
import { FilePond, registerPlugin } from "react-filepond"
//import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
//import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size"
//import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { useUploadReceiptMutation } from "../../app/services/base/reviews"
import {
  selectInfoModal,
  setInfoModal,
} from "../../features/modals/modalsSlice"
import { UpdateFileInfo } from "../../features/info_contents/UpdateFileInfo"
import { useGetCouponsQuery } from "../../app/services/base/coupons"
import { skipToken } from "@reduxjs/toolkit/query"
import { CouponTicket } from "../../features/coupon_ticket/CouponTicket"
import { InfoModal } from "../../features/modals/InfoModal"
import { MediaUpload } from "../../features/send_review/MediaUpload"
import { toast } from "react-toastify"
import { useGetReviewQuery } from "../../app/services/base/reviews"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"
import { Shop } from "../../models/base/shop.interface"
import { useGetShopByIdQuery } from "../../app/services/base/shops"

// FilePondConfig.registerPlugin(
//   FilePondPluginFileValidateType,
//   FilePondPluginFileValidateSize,
//   FilePondPluginImagePreview,
// )
// Define the types for the plugins
type Plugin = {
  default: any
}

export const ReviewsUpload: React.FC = () => {
  useEffect(() => {
    Promise.all<Plugin>([
      import("filepond-plugin-file-validate-type"),
      import("filepond-plugin-file-validate-size"),
      import("filepond-plugin-file-encode"),
      import("filepond-plugin-image-preview"),
    ]).then(
      ([
        { default: FilePondPluginFileValidateType },
        { default: FilePondPluginFileValidateSize },
        { default: FilePondPluginFileEncode },
        { default: FilePondPluginImagePreview },
      ]) => {
        registerPlugin(
          FilePondPluginFileValidateType,
          FilePondPluginFileValidateSize,
          FilePondPluginFileEncode,
          FilePondPluginImagePreview,
        )
      },
    )
  }, [])
  const dispatch = useAppDispatch()
  const infoModal = useAppSelector(selectInfoModal)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { reviewId } = useParams()
  const invitationCode = searchParams.get("v")
  const eShopUrlParam = searchParams.get("e")
  const isDomain = eShopUrlParam?.includes(".")
  const showUploadReceipt = invitationCode ? false : true

  const { data: review } = useGetReviewQuery(reviewId ?? skipToken)
  const { data: eshop } = useGetShopByIdQuery(
    review?.review.eshop_id.toString() ?? skipToken,
  )

  const { couponWithShop } = useGetCouponsQuery(
    eShopUrlParam && !isDomain
      ? { eshop_id: Number(eShopUrlParam), page: 1, perPage: 1 }
      : skipToken,
    {
      selectFromResult: ({ data }) => ({
        couponWithShop: data?.items.find(
          (couponWithShop) =>
            couponWithShop.eshop.id.toString() == eShopUrlParam!,
        ),
      }),
    },
  )
  const user = useAppSelector(selectCurrentUser)

  const [uploadReceipt, { error }] = useUploadReceiptMutation()

  // Show FilePond Docs and RTK Query Mutation for understand this function
  async function handleProcess(
    fieldName: string,
    file: ActualFileObject,
    metadata: { [key: string]: any },
    load: (p: string | { [key: string]: any }) => void,
    error: (errorText: string) => void,
    progress: ProgressServerConfigFunction,
    abort: () => void,
    transfer: (transferId: string) => void,
    options: ProcessServerChunkTransferOptions,
  ) {
    const formData = new FormData()
    formData.append("review_id", reviewId?.toString() || "")
    formData.append("files", file, file.name)

    const controller = new AbortController()
    const signal = controller.signal
    try {
      const response = await uploadReceipt(formData).unwrap()
      progress(true, 100, 100) //force the FilePond progress
      load(response.success)
      toast.success("Prova d'acquisto caricata con successo!")
    } catch (err) {
      error("Ops! Impossibile caricare il file.")
    }

    return {
      abort: () => {
        controller.abort()
        abort()
      },
    }
  }

  return (
    <>
      <div className="container">
        <div className="d-flex flex-column align-items-center align-items-lg-center justify-content-center my-3 py-3 px-3 custom-rounded">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <img
                src={volpeWink}
                className="img-fluid d-inline"
                style={{ maxHeight: "200px" }}
              />
            </div>
            {showUploadReceipt && (
              <div className="col-12 col-lg-6 text-center p-1 px-2">
                <div className="card h-100 p-3 py-5">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h2 className="title title dark-color font-33 font-bolder">
                        Rendi la tua recensione verificata!
                      </h2>
                    </div>
                    <div className="col-12 text-center">
                      <div className="custom-container mx-auto">
                        <p
                          className="gray-color font-16 mx-auto"
                          style={{ maxWidth: "650px" }}
                        >
                          Carica la tua prova d'acquisto* (fattura, ricevuta o
                          conferma d'ordine) e guadagna un altro punto per
                          sbloccare un utile coupon sconto
                        </p>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <div className="custom-container mx-auto"></div>
                    </div>
                    <div className="col-12 text-center my-3">
                      <div className="custom-container mx-auto">
                        <FilePond
                          allowFileTypeValidation={true}
                          allowImagePreview={true}
                          imagePreviewMaxHeight={100}
                          acceptedFileTypes={[
                            "image/jpg",
                            "image/jpeg",
                            "image/png",
                            "application/pdf",
                          ]}
                          allowFileSizeValidation={true}
                          maxFileSize={"4MB"}
                          maxTotalFileSize={"4MB"}
                          allowMultiple={false}
                          maxFiles={1}
                          credits={undefined}
                          allowPaste={false}
                          storeAsFile={false}
                          server={{
                            process: handleProcess,
                          }}
                          labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                        />
                      </div>
                      <p>
                        <small className="prova-acquisto-info dark-color font-16">
                          <b>*</b>Caricare file con peso inferiore a 4 MB. Tipo
                          di file JPG, PNG o PDF. <br /> I tuoi dati verranno
                          utilizzati solo al fine di verificare la tua
                          recensione. Per info{" "}
                          <span
                            className="orange-color underline cursor-pointer"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  title:
                                    "Informativa privacy - Upload prova d'acquisto",
                                  body: <UpdateFileInfo />,
                                }),
                              )
                            }
                          >
                            clicca quì
                          </span>
                          .
                        </small>
                      </p>
                      <p></p>
                      {!user && invitationCode && couponWithShop && (
                        <div className="custom-container mx-auto mt-5">
                          <div className="col-12 text-center">
                            <h2 className="title title dark-color font-23 font-bolder">
                              Ecco il tuo coupon!
                            </h2>
                            <div className="col-12">
                              <ul className="list-group list-style-none">
                                <CouponTicket
                                  couponWithShop={couponWithShop}
                                  isUnlocked={true}
                                  itemCustomClass="coupon-border-bg-white"
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={`col-12 ${
                showUploadReceipt ? "col-lg-6" : ""
              } text-center p-1 px-2`}
            >
              <div className="card h-100 p-3 py-5">
                {/* <div className="col-12 text-center">
                  <img
                    src={volpeOk}
                    className="img-fluid d-inline"
                    style={{
                      maxHeight: "165px",
                      marginTop: "5px",
                      marginBottom: "30px",
                    }}
                  />
                </div> */}
                <h2 className="title title dark-color font-33 font-bolder">
                  Carica foto e video!
                </h2>
                <div className="col-12 text-center">
                  <div className="custom-container mx-auto">
                    <p
                      className="gray-color font-16 mx-auto mb-2"
                      style={{ maxWidth: "650px" }}
                    >
                      Rendi la tua esperienza indimenticabile
                    </p>
                  </div>
                </div>
                <MediaUpload review={review} eshop={eshop} isOpen={true} />
              </div>
            </div>
            <div className="col-12 text-center">
              <Link
                to={`/it/insert-review-ok?id=${reviewId}&v=0&c=${
                  invitationCode ? 1 : 0
                }`}
                className="btn btn-outline-gray font-16 font-semi-bolder flex-grow-1 px-5 py-2 mt-3 me-3"
              >
                Salta
              </Link>
              <Link
                to={`/it/insert-review-ok?id=${reviewId}&v=0&c=${
                  invitationCode ? 1 : 0
                }`}
                className="btn btn-animation btn-action btn-gradient-orange font-16 font-semi-bolder flex-grow-1 px-5 py-2 mt-3"
              >
                Continua
              </Link>
            </div>
          </div>
        </div>
      </div>
      {infoModal && <InfoModal />}
    </>
  )
}
