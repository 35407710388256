import React, { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  selectCouponModal,
  selectEsaScoreModal,
  selectInfoModal,
  selectOnExitModal,
  selectReportModal,
  selectShareModal,
  setOnExitModal,
} from "../../features/modals/modalsSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import useWindowSize from "../../utils/useWindowSize"
import { Helmet } from "react-helmet"
import { formatNumber } from "../../utils/stringUtils"
// import { LocalBusinessSchema } from "../../features/schemas/LocalBusinessSchema"
import { skipToken } from "@reduxjs/toolkit/query"
import { EsaScoreModal } from "../../features/modals/ESAScoreModal"
import { EShopCouponsCard } from "../../features/eshop/cards/EShopCouponsCard"
import { Alert } from "../../features/alert/Alert"
import { useGetCouponsQuery } from "../../app/services/base/coupons"
import { calcTotalReviews, isPlanWithBanners } from "../../utils/shopUtils"
import { useGetShopsQuery } from "../../app/services/base/shops"
import { useGetSearchDomainsQuery } from "../../app/services/esaScraper/domains"
import { OnExitModal } from "../../features/modals/OnExitModal"
import Cookies from "js-cookie"
import { ReviveBanner } from "../../features/revive_banner/ReviveBanner"
import { EShopStickyBar } from "../../features/eshop/sticky_bar/EShopStickyBar"
import { AppBreadcrumb } from "../../features/app_breadcrumb/AppBreadcrumb"
import { EShopDetails } from "../../features/eshop/details/EShopDetails"
import { EShopDetailsCard } from "../../features/eshop/cards/EShopDetailsCard"
import { EShopInfoCard } from "../../features/eshop/cards/EShopInfoCard"
import { EShopCategoriesCard } from "../../features/eshop/cards/EShopCategoriesCard"
import { EShopCertificationsCard } from "../../features/eshop/cards/EShopCertificationsCard"
import { EShopMediaCard } from "../../features/eshop/cards/EShopMediaCard"
import { EShopSocialsCard } from "../../features/eshop/cards/EShopSocialsCard"
import { EShopDisclaimer } from "../../features/eshop/disclaimer/EShopDisclaimer"
import { EShopMainContent } from "../../features/eshop/main_content/EShopMainContent"
import { InfoModal } from "../../features/modals/InfoModal"
import { ESAValuesModal } from "../../features/modals/ESAValuesModal"
import { ShareModal } from "../../features/modals/ShareModal"
import { CouponModal } from "../../features/modals/CouponModal"
import { ReportModal } from "../../features/modals/ReportModal"
import { forceIsOpenTo } from "../../features/search_engine/searchEngineSlice"
import { eshopIdDecoder } from "../../utils/shopUtils"
import { EShopEsaInfoCard } from "../../features/eshop/cards/EShopEsaInfoCard"
import { useReadyState } from "../../features/utilities/RouteLoader"
import revBooster from "../../assets/images/revBooster.png"

export const EShop: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { eShopDomain } = useParams()
  const setReady = useReadyState()

  // if eshopDomainOrId is a number, it's an id, otherwise it's a domain
  const eShopDomainString = isNaN(Number(eShopDomain)) ? eShopDomain : undefined
  const eShopId = isNaN(Number(eShopDomain))
    ? undefined
    : eshopIdDecoder(Number(eShopDomain))

  let data
  if (eShopId) {
    data = useGetShopsQuery(eShopId ? { domain: eShopId } : skipToken, {
      selectFromResult: ({ data, isError }) => ({
        eShop: data?.find((shop) => shop.id === eShopId),
        isError,
      }),
    })
    if (data.eShop) {
      // set page url to the correct domain /it/eshop/<domain>
      navigate(`/it/eshop/${data.eShop.domain.replace("/", "*")}`, {
        replace: true,
      })
    }
    // // set page url to the correct domain /it/eshop/<domain>
    // window.location.href = '/404';
  } else if (eShopDomain) {
    data = useGetShopsQuery(
      eShopDomain ? { domain: eShopDomainString } : skipToken,
      {
        selectFromResult: ({ data, isError }) => ({
          eShop: data?.find(
            (shop) =>
              shop.domain === eShopDomain ||
              shop.domain.replace("/", "*") === eShopDomain,
          ),
          isError,
        }),
      },
    )
  } else {
    setReady(true)
    window.location.href = "/404"
  }
  const { eShop, isError } = data || {}
  // Queste chiamate vengono eseguite qui per sapere se esistono o meno i dati necessari
  // Non causano doppie chiamate al server poichè la prima volta i parametri sono uguali
  // e quindi alle seconde chiamate (che avvengono nei rispettivi Tab) risponde la cache
  const { data: coupons } = useGetCouponsQuery(
    eShop ? { eshop_id: eShop.id, page: 1, perPage: 1 } : skipToken,
  )
  // Ottengo lo shop anche da Scraper per avere le categorie
  const { scraperShop } = useGetSearchDomainsQuery(
    eShopDomain
      ? {
          filters: {
            eShopDomain: { name: "", label: "", value: eShopDomain },
            enrichData: { name: "", label: "", value: true },
            // size: {name:"", label:"", value:1},
          },
          searchAfter: undefined,
        }
      : skipToken,
    {
      selectFromResult: ({ data }) => ({
        scraperShop: data?.hits.hits.find(
          (shop) => shop._source.domain == eShopDomain,
        ),
      }),
    },
  )
  // *****

  const infoModal = useAppSelector(selectInfoModal)
  const esaScoreModal = useAppSelector(selectEsaScoreModal)
  const shareModal = useAppSelector(selectShareModal)
  const reportModal = useAppSelector(selectReportModal)
  const couponModal = useAppSelector(selectCouponModal)
  const onExitModal = useAppSelector(selectOnExitModal)

  const areBannersActive = false // eShop ? isPlanWithBanners(eShop.plan) : false

  let totalReviews = eShop ? calcTotalReviews(eShop) : 0

  // TODO: is it correct?
  let showInfotab = eShop && eShop.plan == "ibrido" ? false : true
  let showEsaInfotab = eShop && eShop.plan == "ibrido" ? true : true

  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768

  const mainCategory = scraperShop?._source.esa_categories
    ? scraperShop?._source.esa_categories[0]
    : false

  useEffect(() => {
    if (eShop && isPlanWithBanners(eShop.plan)) {
      const scriptElement = document.createElement("script")
      scriptElement.src = "//ads.themoneytizer.com/s/gen.js?type=6"
      scriptElement.async = true
      document.body.appendChild(scriptElement)

      const requestScriptElement = document.createElement("script")
      requestScriptElement.src =
        "//ads.themoneytizer.com/s/requestform.js?siteId=120160&formatId=6"
      requestScriptElement.async = true
      document.body.appendChild(requestScriptElement)

      return () => {
        document.body.removeChild(scriptElement)
        document.body.removeChild(requestScriptElement)
      }
    }
  }, [eShop])

  useEffect(() => {
    if (
      scraperShop &&
      eShop &&
      (eShop.plan == "free" || eShop.plan == "ibrido") &&
      !Cookies.get("customonexitcookie")
    ) {
      setTimeout(() => {
        dispatch(setOnExitModal(scraperShop._source))
        Cookies.set("customonexitcookie", "true", { expires: 7 })
      }, 5000)
    }
  }, [scraperShop, eShop])

  useEffect(() => {
    dispatch(forceIsOpenTo(false))
  }, [])

  if (!eShop && isError) {
    setReady(true)
    window.location.href = "/404"
  }

  if (eShop) {
    setReady(true)
  } else {
    setReady(false)
  }

  // TODO: Add this on title? eShop.eshopData?.showcaseData?.eshop_name

  return (
    <>
      {eShop && (
        <>
          <Helmet>
            <title>
              Recensioni {String(eShop.domain)} - Leggi tutte le opinioni di{" "}
              {String(eShop.domain)}
            </title>
            <meta
              name="description"
              content={`${String(eShop.domain)} ha ottenuto oltre ${String(
                totalReviews,
              )} recensioni, l'opinione dei clienti è molto positiva! Leggi le opinioni e scrivi la tua`}
            />
            <link
              rel="canonical"
              href={`${String(
                import.meta.env.VITE_B2C_BASE_URL,
              )}it/eshop/${String(eShop.domain).replace("/", "*")}`}
            />
            <meta
              property="og:title"
              content={`Valutato ${formatNumber(
                eShop.rating ? eShop.rating : 0,
                1,
              )} in media con ${totalReviews} recensioni`}
            />
            <meta
              property="og:description"
              content={`${eShop.domain} ha ottenuto oltre ${totalReviews} recensioni, l'opinione dei clienti è molto positiva! Leggi le opinioni e scrivi la tua`}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content={`${import.meta.env.VITE_B2C_BASE_URL}it/eshop/${
                eShop.domain
              }`}
            />
            <meta
              property="og:image:url"
              content={`${import.meta.env.VITE_B2C_BASE_URL}it/shareThumb/1/${
                eShop.id
              }`}
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:site" content="@Eshoppingadvis" />
            <meta
              property="twitter:title"
              content={`Valutato ${formatNumber(
                eShop.rating ? eShop.rating : 0,
                1,
              )} in media con ${totalReviews} recensioni`}
            />
            <meta
              property="twitter:description"
              content={`${eShop.domain} ha ottenuto oltre ${totalReviews} recensioni, l'opinione dei clienti è molto positiva! Leggi le opinioni e scrivi la tua`}
            />
            <meta
              property="twitter:image"
              content={`${
                import.meta.env.VITE_CDN_BASE_URL
              }/assets/img/share.png`}
            />
          </Helmet>
          {/* <LocalBusinessSchema
            shop={eShop}
            reviews={[]}
            hideReviews
            hideDataset
          /> */}
          <EShopStickyBar shop={eShop} />
          <div className="container">
            <div className="container mx-auto">
              <AppBreadcrumb
                showMeta={false}
                routes={
                  mainCategory
                    ? [
                        { name: "Home", slug: "/" },
                        {
                          name: mainCategory.name,
                          slug: "/it/categorie/" + mainCategory.slug,
                        },
                        { name: eShop?.domain, slug: eShop?.domain },
                      ]
                    : [
                        { name: "Home", slug: "/" },
                        { name: eShop?.domain, slug: eShop?.domain },
                      ]
                }
              />
              <div className="eshop-grid-container mt-3">
                <div className="eshop-details-container">
                  <EShopDetails shop={eShop} />
                </div>
                <div className="eshop-cards-container">
                  <h6 className="d-lg-none mb-0 bolder-dark-title font-16 mt-4 mt-lg-0">
                    Informazioni sull'eCommerce
                  </h6>
                  <div className="eshop-cards-container-mobile">
                    <div className="d-flex flex-row flex-lg-column justify-content-center align-items-top py-3 py-lg-0">
                    {/* {isPlanWithBanners(eShop.plan) &&(
                      <div
                        className="card eshop-custom-card mb-0 mb-lg-2 me-2 me-lg-0 hide-scrollbar"
                        style={{
                          overflowY: "scroll",
                          width: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <div className="row align-items-center h-100">
                          <Link
                            to={
                              "https://eshoppingadvisor.com/aziende/revbooster-aumenta-vendite-ridurre-costi/)"
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={revBooster}
                              alt={"Scopri Revbooster"}
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </div>
                    )}  */}
                      {showEsaInfotab && <EShopEsaInfoCard shop={eShop} />}
                      <EShopDetailsCard shop={eShop} />
                      {coupons && coupons.items.length > 0 && (
                        <EShopCouponsCard shop={eShop} />
                      )}
                      {showInfotab && <EShopInfoCard shop={eShop} />}
                      {scraperShop?._source.esa_categories && (
                        <EShopCategoriesCard
                          categories={scraperShop._source.esa_categories}
                        />
                      )}
                      {eShop.id != 255818 &&
                        (eShop.eshopData.structured_data.badge_ecs ||
                          (eShop.aicel_logo && eShop.aicel_logo != "")) && (
                          <EShopCertificationsCard shop={eShop} />
                        )}
                      {eShop.plan != "free" &&
                        (eShop.eshopData.structured_data.video ||
                          eShop.eshopData.structured_data.image_gallery) && (
                          <EShopMediaCard shop={eShop} />
                        )}

                      {areBannersActive && (
                        <div
                          className="card eshop-custom-card mb-0 mb-lg-4 me-2 me-lg-0 hide-scrollbar"
                          style={{ overflowY: "scroll", width: "auto" }}
                        >
                          <div className="card-body d-flex flex-column px-3 py-4 p-lg-3">
                            <ReviveBanner
                              zoneId="17"
                              shop={scraperShop?._source}
                            />
                          </div>
                        </div>
                      )}

                      {(eShop.eshopData.showcaseData.facebook ||
                        eShop.eshopData.showcaseData.instagram ||
                        eShop.eshopData.showcaseData.linkedin ||
                        eShop.eshopData.showcaseData.pinterest ||
                        eShop.eshopData.showcaseData.twitter ||
                        eShop.eshopData.showcaseData.youtube) && (
                        <EShopSocialsCard shop={eShop} />
                      )}

                      {eShop.eshopData.fb_plugin && (
                        <div
                          className="card mx-auto p-3 mb-3 d-none d-lg-block"
                          dangerouslySetInnerHTML={{
                            __html: eShop.eshopData.fb_plugin,
                          }}
                        />
                      )}

                      {!isMobile && (
                        <EShopDisclaimer
                          isMobile={isMobile}
                          shop={eShop}
                          isReviewDetails={false}
                        />
                      )}

                      {areBannersActive && totalReviews > 0 && (
                        <div
                          className="card eshop-custom-card mb-0 mb-lg-4 me-2 me-lg-0 hide-scrollbar"
                          style={{ overflowY: "scroll", width: "auto" }}
                        >
                          <div className="card-body d-flex flex-column px-3 py-4 p-lg-3">
                            <ReviveBanner
                              zoneId="21"
                              shop={scraperShop?._source}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="eshop-tabs-container">
                  <div id="eshop-info-top" className="d-flex flex-column">
                    {eShop.eshopData.isDisabled ? (
                      <Alert
                        message={
                          <>
                            <p className="blacklist-title">
                              <strong>
                                Attenzione! Abbiamo rilevato un’irregolarità su
                                questo dominio
                              </strong>
                            </p>
                            <p>
                              Abbiamo individuato numerose recensioni non
                              veritiere per questo eCommerce e abbiamo
                              provveduto prontamente a rimuoverle.
                            </p>
                            <p>
                              Nel caso in cui identificheremo ulteriori
                              recensioni sospette su questo profilo procederemo
                              a rimuovere anche queste.
                            </p>
                            <p>
                              Il nostro scopo è quello di garantire ai nostri
                              clienti un’esperienza di shopping online sicura e
                              consapevole, attraverso un ecosistema sicuro ed
                              affidabile nel quale ogni recensione letta
                              equivalga ad un feedback reale.
                            </p>
                            <p>
                              Per questo motivo ci teniamo a segnalare alla
                              nostra community di recensori qualsiasi abuso o
                              uso improprio della nostra piattaforma prendendo
                              gli opportuni provvedimenti a riguardo.
                            </p>
                          </>
                        }
                      />
                    ) : (
                      ""
                    )}
                    <EShopMainContent
                      shop={eShop}
                      // reviews={reviews?.hits.hits}
                      // productsReviews={productsReviews?.hits.hits}
                      scraperShop={scraperShop?._source}
                    />
                    {isMobile && (
                      <EShopDisclaimer
                        isMobile={isMobile}
                        shop={eShop}
                        isReviewDetails={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {infoModal && <InfoModal />}
          {esaScoreModal && <EsaScoreModal />}
          <ESAValuesModal />
          {shareModal && <ShareModal />}
          {reportModal && <ReportModal />}
          {couponModal && <CouponModal />}
          {/* {onExitModal && <OnExitModal />} */}
        </>
      )}
    </>
  )
}
