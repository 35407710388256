import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import Root from "./Root"
import { CategoriesLoader } from "./app/loaders/categories.loader"
import { SearchLoader } from "./app/loaders/search.loader"
import { HomeLoader } from "./app/loaders/home.loaders"
import { CouponsLoader } from "./app/loaders/coupons.loader"
import { ShopsLoader } from "./app/loaders/shops.loader"
import { ReviewsLoader } from "./app/loaders/reviews.loader"
import { DeleteAccount } from "./pages/reserved_area/delete_account/DeleteAccount"
import { ReviewsModify } from "./pages/reserved_area/reviews_modify/ReviewsModify"
import { ReviewsModifyPublic } from "./pages/reviews_modify_public/ReviewsModifyPublic"
import { ReviewsModifyPublicOk } from "./pages/reviews_modify_public/ReviewsModifyPublicOk"
import { ReviewDeleteConfirm } from "./pages/reserved_area/review_delete_confirm/ReviewDeleteConfirm"
import { ProductReview } from "./pages/reserved_area/product_review/ProductReview"
import { NotFound } from "./pages/not_found/NotFound"
import { Page } from "./pages/page/Page"
import { ReviewsModifyOk } from "./pages/reserved_area/reviews_modify/ReviewsModifyOk"
import { UpdateReviewMedia } from "./pages/reserved_area/reviews_modify/UpdateReviewMedia"
import { UpdateProductReviewMedia } from "./pages/reserved_area/reviews_modify/UpdateProductReviewMedia"
import { WriteReview } from "./pages/write_review/WriteReview"
import { ErrorPage } from "./pages/error_page/ErrorPage"
import { Home } from "./pages/home/Home"
import { Login } from "./pages/reserved_area/login/Login"
import { UserLogin } from "./pages/reserved_area/login/UserLogin"
import { LostPassword } from "./pages/reserved_area/lost_password/LostPassword"
import { CredentialRecoveryConfirmed } from "./pages/credential_recovery_confirmed/CredentialRecoveryConfirmed"
import { ProtectedRoute } from "./features/protected_route/ProtectedRoute"
import { Reserved } from "./pages/reserved_area/layout/Reserved"
import Profile from "./pages/reserved_area/profile/Profile"
import Reviews from "./pages/reserved_area/reviews/Reviews"
import ProductReviews from "./pages/reserved_area/reviews/ProductReviews"
import { ProductReviewsModify } from "./pages/reserved_area/reviews_modify/ProductReviewsModify"
import { InvitationsOrders } from "./pages/reserved_area/invitations_orders/InvitationsOrders"
import Awards from "./pages/reserved_area/awards/Awards"
import UserPage from "./pages/reserved_area/user_page/UserPage"
import { Categories } from "./pages/categories/Categories"
import { Category } from "./pages/categories/pages/Category"
import { Coupons } from "./pages/coupons/Coupons"
import { CouponInfo } from "./pages/coupon_info/CouponInfo"
import { RegistrationConfirmed } from "./pages/registration_confirmed/RegistrationConfirmed"
import { EmailVerified } from "./pages/email_verified/EmailVerified"
import { ClientRegistration } from "./pages/reserved_area/registration/ClientRegistration"
import { ShopsResult } from "./pages/search/shops_result/ShopsResult"
import { ProductsResult } from "./pages/search/products_result/ProductsResult"
import { EShop } from "./pages/eshop/EShop"
import { ReviewDetails } from "./pages/review_details/ReviewDetails"
import { ProductReviewDetails } from "./pages/review_details/ProductReviewDetails"
import { ProductDetails } from "./pages/product_details/ProductDetails"
import { SendReview } from "./pages/send_review/SendReview"
import { InsertReviewOk } from "./pages/insert_review/InsertReviewOk"
import { ReviewsUpload } from "./pages/reviews_upload/ReviewsUpload"
//import { AboutUs } from "./pages/about_us/AboutUs"
import { Project } from "./pages/project/Project"
import { ReviewerRanking } from "./pages/reviewer_ranking/ReviewerRanking"
import { Advantages } from "./pages/advantages/Advantages"
import { ContactUs } from "./pages/contact_us/ContactUs"
import { Disclaimer } from "./pages/disclaimer/Disclaimer"
import { CookiesPolicy } from "./pages/cookies_policy/CookiesPolicy"
import UserPublicPage from "./pages/user_public_page/UserPublicPage"
import { Newsletter } from "./pages/newsletter/Newsletter"
import { NewsletterActivated } from "./pages/newsletter/NewsletterActivated"
import { NoIndexReviews } from "./pages/no_index_reviews/NoIndexReviews"
import RouteLoader from "./features/utilities/RouteLoader"

//import { FAQ } from "./pages/faq/FAQ" -> Lazy Loaded
//import { Manleva } from "./pages/manleva/Manleva" -> Lazy Loaded
// import { PrivacyPolicy } from "./pages/privacy_policy/PrivacyPolicy" -> Lazy Loaded

const preRenderKey = import.meta.env.VITE_URL_PRERENDER_KEY || "preRender"

// TODO For future optimization use lazyLoad function
import React, { lazy, Suspense, ComponentType } from "react"
function lazyLoad<T extends ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
): React.LazyExoticComponent<T> {
  return lazy(importFunc)
}
const FAQ = lazyLoad(() => import("./pages/faq/FAQ"))
const Manleva = lazyLoad(() => import("./pages/manleva/Manleva"))
const DeleteAccountInfo = lazyLoad(
  () => import("./pages/delete_account_info/DeleteAccountInfo"),
)
const PrivacyPolicy = lazyLoad(
  () => import("./pages/privacy_policy/PrivacyPolicy"),
)
const AboutUs = lazyLoad(() => import("./pages/about_us/AboutUs"))
// const Login = lazyLoad(() => import("./pages/reserved_area/login/Login"));
// const LostPassword = lazyLoad(() => import("./pages/reserved_area/lost_password/LostPassword"));
// const CredentialRecoveryConfirmed = lazyLoad(() => import("./pages/credential_recovery_confirmed/CredentialRecoveryConfirmed"));
// const EShop = lazyLoad(() => import("./pages/eshop/EShop"));
// const Reviews = lazyLoad(() => import("./pages/reserved_area/reviews/Reviews"));
// const ProductReviews = lazyLoad(() => import("./pages/reserved_area/reviews/ProductReviews"));

export const router = (store: any) => {
  const homeLoader = new HomeLoader(store)
  const categoriesLoader = new CategoriesLoader(store)
  const reviewsLoader = new ReviewsLoader(store)
  const shopsLoader = new ShopsLoader(store)
  const searchLoader = new SearchLoader(store)
  const couponLoader = new CouponsLoader(store)
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/:lang?/" element={<Root />}>
        <Route errorElement={<ErrorPage />}>
          <Route
            index
            element={<Home />}
            // loader={homeLoader.listLoader}
          />
          <Route
            path="recensione/gestione/:reviewId/:code"
            element={
              <Reserved content={ReviewsModifyPublic} hideSidebar={true} />
            }
          />
          <Route
            path="reviews-modify-ok"
            element={
              <Reserved content={ReviewsModifyPublicOk} hideSidebar={true} />
            }
          />
          <Route path="areariservata">
            <Route path="user-login" element={<UserLogin />} />
            <Route path="login" element={<Login />} />
            <Route path="recupera-credenziali">
              <Route index element={<LostPassword />} />
              <Route path="ok" element={<CredentialRecoveryConfirmed />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="profilo" element={<Reserved content={Profile} />} />
              <Route
                path="elimina"
                element={<Reserved content={DeleteAccount} />}
              />
              <Route path="reviews" element={<Reserved content={Reviews} />} />
              <Route
                path="reviews-modify/:reviewId"
                element={<Reserved content={ReviewsModify} />}
              />
              <Route
                path="modifica-media-recensioni/:reviewId"
                element={<Reserved content={UpdateReviewMedia} />}
              />
              <Route
                path="reviews-modify-ok"
                element={<Reserved content={ReviewsModifyOk} />}
              />
              <Route
                path="reviews-delete-ok"
                element={<Reserved content={ReviewDeleteConfirm} />}
              />
              <Route
                path="product-reviews"
                element={<Reserved content={ProductReviews} />}
              />
              <Route
                path="product-reviews-modify/:reviewId"
                element={<Reserved content={ProductReviewsModify} />}
              />
              <Route
                path="modifica-media-recensioni-prodotto/:reviewId"
                element={<Reserved content={UpdateProductReviewMedia} />}
              />
              <Route
                path="reviews-acquisti"
                element={<Reserved content={InvitationsOrders} />}
              />
              <Route
                path="recensione-prodotto/:invitationOrderId/:key/:productId"
                element={<Reserved content={ProductReview} />}
              />
              <Route
                path="riconoscimenti"
                element={<Reserved content={Awards} />}
              />
              <Route path="vetrina" element={<Reserved content={UserPage} />} />
            </Route>
          </Route>
          <Route
            path="categorie"
            element={<Categories />}
            // loader={categoriesLoader.listLoader}
          >
            <Route
              path=":categorySlug"
              element={<Category />}
              // loader={categoriesLoader.detailLoader}
            />
            <Route
              path={`:categorySlug/${preRenderKey}`}
              element={<Category />}
              // loader={categoriesLoader.detailLoader}
            />
          </Route>
          <Route
            path="coupon/:categorySlug"
            element={<Coupons />}
            // loader={couponLoader.listLoader}
          />
          <Route
            path="coupon-eshop/:eShopDomain"
            element={<Coupons />}
            // loader={couponLoader.listLoader}
          />
          <Route path="info">
            <Route path="coupon-info" element={<CouponInfo />} />
          </Route>
          <Route path="registrazione">
            <Route path="ok" element={<RegistrationConfirmed />} />
            <Route path="attivazione/:optinCode" element={<EmailVerified />} />
            <Route path="cliente" element={<ClientRegistration />} />
          </Route>
          <Route path="search">
            <Route
              path="eshopSearch/:searchText"
              element={<ShopsResult />}
              // loader={searchLoader.listLoader}
            ></Route>
            <Route
              path="prodotti/:searchText"
              element={<ProductsResult />}
            ></Route>
            <Route
              path="migliori-prodotti/:categorySlug"
              element={<ProductsResult />}
            ></Route>
          </Route>
          <Route
            path="eshop/:eShopDomain"
            element={
              <RouteLoader>
                <EShop />
              </RouteLoader>
            }
            // loader={shopsLoader.detailLoader}
          />
          <Route
            path={`eshop/:eShopDomain/${preRenderKey}`}
            element={<EShop />}
            // loader={shopsLoader.detailLoader}
          />
          <Route
            path="vetrina/:source/:eShopDomain"
            element={<NoIndexReviews />}
            // loader={shopsLoader.detailLoader}
          />
          <Route
            path="reviews/:reviewId"
            element={<ReviewDetails />}
            // loader={reviewsLoader.detailLoader}
          />
          <Route
            path="product-reviews/:reviewId"
            element={<ProductReviewDetails />}
            // loader={reviewsLoader.detailLoader}
          />
          {/* URL Indicizzata */}
          <Route
            path="prodotti-eshop/:eShopDomain/:productName/q/:linkDomainId"
            element={<ProductDetails />}
          />
          {/* URL Non Indicizzata */}
          <Route
            path="products/:eShopDomain/:productName/q/:linkDomainId"
            element={<ProductDetails />}
          />
          <Route
            path={`prodotti-eshop/:eShopDomain/:productName/q/:linkDomainId/${preRenderKey}`}
            element={<ProductDetails />}
          />
          <Route
            path={`products/:eShopDomain/:productName/q/:linkDomainId/${preRenderKey}`}
            element={<ProductDetails />}
          />
          <Route
            path="prodotti-eshop/:eShopDomain"
            element={<ProductsResult />}
          />
          <Route
            path={`prodotti-eshop/:eShopDomain/${preRenderKey}`}
            element={<ProductsResult />}
          />
          <Route path="utenti/:userId/:userName" element={<UserPublicPage />} />
          <Route path="scrivi-recensione" element={<WriteReview />} />
          <Route path="invia-recensione" element={<SendReview />} />
          <Route path="insert-review" element={<SendReview />} />
          <Route
            path="invia-recensione/:eshopId/:code"
            element={<SendReview />}
          />
          <Route path="insert-review/:eshopId/:code" element={<SendReview />} />
          <Route path="insert-review-ok" element={<InsertReviewOk />} />
          <Route path="reviews-upload/:reviewId" element={<ReviewsUpload />} />
          <Route
            path="chi-siamo"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route path="il-progetto" element={<Project />} />
          <Route path="classifica-recensori" element={<ReviewerRanking />} />
          <Route
            path="faq"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <FAQ />
              </Suspense>
            }
          />
          <Route path="vantaggi" element={<Advantages />} />
          <Route path="contattaci" element={<ContactUs />} />
          <Route
            path="manleva"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Manleva />
              </Suspense>
            }
          />
          <Route
            path="delete-account-info"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <DeleteAccountInfo />
              </Suspense>
            }
          />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route
            path="informativa-privacy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route path="informativa-cookies" element={<CookiesPolicy />} />
          <Route path="newsletter/iscriviti" element={<Newsletter />} />
          <Route
            path="attivazione-newsletter/:id/:code"
            element={<NewsletterActivated />}
          />
          <Route
            path="user-unsuscribed"
            element={
              <Page
                title={"Disiscrizione completata"}
                content={
                  <p className="my-3">
                    La tua richiesta di disiscrizione è stata elaborata con
                    successo. Non riceverai più le nostre comunicazioni email.
                  </p>
                }
              />
            }
          />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="404" replace />} />
        </Route>
      </Route>,
    ),
  )
}
