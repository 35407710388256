import { tryToParse } from "./objectsUtils"

export const parseReviewVideo = async (reviewVideo: string) => {
  let reviewVideoObj = await tryToParse(reviewVideo)

  if (reviewVideoObj && reviewVideoObj.url.includes("youtube")) {
    reviewVideoObj.url = `https://www.youtube-nocookie.com/embed/${reviewVideoObj.videoId}`
  } else if (reviewVideoObj) {
    reviewVideoObj.url = `${reviewVideoObj.url}?dnt=true`
  }
  return reviewVideoObj
}

export const getVideoThumbnail = async (id: string, videoUrl: string) => {
  let height = 166

  if (videoUrl.includes("youtube")) {
    const youtubeThumbnailUrl =
      height > 166
        ? `https://img.youtube.com/vi/${id}/hqdefault.jpg`
        : `https://img.youtube.com/vi/${id}/mqdefault.jpg`

    return youtubeThumbnailUrl
  } else if (videoUrl.includes("vimeo")) {
    try {
      const response = await fetch(
        `https://api.vimeo.com/videos/${id}/pictures`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_VIMEO_ACCESS_TOKEN}`,
          },
        },
      )

      const data = await response.json()

      if (data.data && data.data.length > 0) {
        const pictures = data.data[0].sizes
        const thumb = pictures.find((size: any) => size.height === height)

        if (thumb) {
          return thumb.link_with_play_button as string
        }
      }

      return ""
    } catch (error) {
      console.error("Error fetching video thumbnail:", error)
      return ""
    }
  }
  return ""
}

export const uploadVimeoVideo = async (file: any, videoTitle: string) => {
  debugger
  try {
    const createVideoResponse = await fetch("https://api.vimeo.com/me/videos", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_VIMEO_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
      },
      body: JSON.stringify({
        upload: {
          approach: "tus",
          size: file.size,
          // redirect_url: "{url}",
        },
        name: videoTitle,
        description: " ",
      }),
    }).then((response) => response.json())

    if (
      createVideoResponse.upload.approach == "tus" &&
      createVideoResponse.upload.upload_link
    ) {
      let offset = 0
      const chunkSize = 512 * 1024 * 1024 // Chunk size in bytes (512 MB in this example)

      // Function to send a PATCH request with binary data
      async function sendPatchRequest(offset: any, data: any) {
        const response = await fetch(createVideoResponse.upload.upload_link, {
          method: "PATCH",
          headers: {
            "Tus-Resumable": "1.0.0",
            "Upload-Offset": offset,
            "Content-Type": "application/offset+octet-stream",
            Authorization: `Bearer ${import.meta.env.VITE_VIMEO_ACCESS_TOKEN}`,
          },
          body: data,
        })

        return response
      }

      // Function to send a HEAD request to verify the upload
      async function verifyUpload() {
        const response = await fetch(createVideoResponse.upload.upload_link, {
          method: "HEAD",
          headers: {
            "Tus-Resumable": "1.0.0",
            Accept: "application/vnd.vimeo.*+json;version=3.4",
            Authorization: `Bearer ${import.meta.env.VITE_VIMEO_ACCESS_TOKEN}`,
          },
        })

        return response
      }

      // Upload chunks of the video file
      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize)
        const response = await sendPatchRequest(offset, chunk)

        if (response.ok) {
          const newOffset = parseInt(
            response.headers.get("Upload-Offset") || "",
            10,
          )

          if (newOffset === file.size) {
            // Entire file uploaded
            console.log("Video uploaded successfully!")
            break
          } else if (newOffset > offset) {
            // Continue uploading from the new offset
            offset = newOffset
          } else {
            // Error handling: Unexpected behavior
            console.error("Unexpected response. Upload failed.")
            break
          }
        } else {
          // Error handling: Handle the error response
          console.error("Error uploading chunk. Status:", response.status)
          break
        }
      }

      // Verify the upload after completion
      const verificationResponse = await verifyUpload()

      if (verificationResponse.ok) {
        const uploadLength = parseInt(
          verificationResponse.headers.get("Upload-Length") || "",
          10,
        )
        const uploadOffset = parseInt(
          verificationResponse.headers.get("Upload-Offset") || "",
          10,
        )

        if (uploadLength === uploadOffset) {
          console.log(
            "Upload verification successful. Video uploaded completely.",
          )
        } else {
          // Resume the upload if needed
          console.log("Upload verification failed. Resuming upload...")
          // Implement code to resume the upload using PATCH, similar to the upload process
        }
      } else {
        // Error handling: Handle the verification error response
        console.error(
          "Error verifying upload. Status:",
          verificationResponse.status,
        )
      }
    }

    return createVideoResponse.link
  } catch (error) {
    console.error("Error uploading video:", error)
  }
}
