import React from "react"
import { FaqAccordionItem } from "./FaqAccordionItem"
import { Accordion } from "react-bootstrap"
import { Helmet } from "react-helmet"

export const RevBoosterAccordion: React.FC = () => {
  const items = [
    {
      subtitle:
        "COS'È REVBOOSTER E COME PUÒ AUMENTARE LE VENDITE DEL MIO E-COMMERCE?",
      title:
        "Scopri RevBooster: La tua soluzione per il successo nell'e-commerce",
      contentHtml:
        "<p>RevBooster è una soluzione innovativa basata sull'analisi avanzata delle recensioni e sull'ottimizzazione della strategia di conversione. Grazie a un approccio data-driven e alla nostra metodologia esclusiva, RevBooster aiuta gli e-commerce a incrementare il tasso di conversione e a ridurre i costi, migliorando la fiducia degli utenti e ottimizzando il funnel di vendita.</p>",
    },
    {
      subtitle:
        "COME FUNZIONA REVBOOSTER E IN COSA SI DIFFERENZIA DAGLI ALTRI STRUMENTI DI OTTIMIZZAZIONE DELLE CONVERSIONI?",
      title: "Metodologia unica per risultati straordinari",
      contentHtml:
        "<p>RevBooster analizza il comportamento degli utenti e le recensioni per generare un report dettagliato con azioni concrete per migliorare le vendite. Il nostro approccio unico si basa sulla <b>matrice di Eisenhower</b>, che permette di identificare le priorità strategiche con il massimo impatto. A differenza di altre soluzioni, RevBooster non richiede installazioni tecniche: i risultati vengono forniti sotto forma di un <b>report PDF</b>, che viene spiegato in un workshop di 2 ore dai nostri consulenti esperti.</p>",
    },
    {
      subtitle: "REVBOOSTER È COMPATIBILE CON IL MIO E-COMMERCE?",
      title: "Compatibilità universale per ogni piattaforma",
      contentHtml:
        "<p>Sì! RevBooster è progettato per essere compatibile con <b>qualsiasi e-commerce</b>, indipendentemente dalla piattaforma utilizzata, come Shopify, WooCommerce, Magento, Prestashop e molte altre. Non richiede installazione, poiché il valore di RevBooster si traduce in un <b>report PDF dettagliato</b>, spiegato passo dopo passo durante un workshop di 2 ore con i nostri consulenti. Questo approccio garantisce azioni concrete per aumentare conversioni e vendite senza dover integrare nuovi strumenti tecnici.</p>",
    },
    {
      subtitle: "QUANTO COSTA REVBOOSTER E QUALE ROI POSSO ASPETTARMI?",
      title: "Investimento intelligente per risultati garantiti",
      contentHtml:
        "<p>RevBooster offre piani personalizzati in base alle esigenze del tuo e-commerce. I nostri clienti vedono un aumento medio del <b>15% nei ricavi</b> e una riduzione dei costi entro i primi mesi di implementazione delle strategie suggerite. Grazie alla metodologia data-driven e all'ottimizzazione mirata, il ritorno sull'investimento (ROI) è rapido e misurabile.</p>",
    },
    {
      subtitle: "COME POSSO PROVARE REVBOOSTER SUL MIO E-COMMERCE?",
      title: "Inizia subito con una demo gratuita",
      contentHtml:
        "<p>Puoi richiedere una <b>demo gratuita di RevBooster </b>per scoprire come può ottimizzare il tuo e-commerce senza alcun impegno. Basta registrarsi al <a href='https://eshoppingadvisor.com/aziende/revbooster-aumenta-vendite-ridurre-costi/' target='_blank'>link</a> e iniziare a testarne l'efficacia. Scopri in pochi giorni come RevBooster può aumentare conversioni e vendite con un impatto immediato sui tuoi risultati. </p>",
    },
  ]

  return (
    <div className="container my-5">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: items.map((item) => ({
              "@type": "Question",
              name: item.subtitle,
              acceptedAnswer: {
                "@type": "Answer",
                text: item.contentHtml.replace(/<[^>]*>/g, ""),
              },
            })),
          })}
        </script>
      </Helmet>
      <div className="row">
        <div className="col-12 my-3">
          <Accordion
            className="xs-transparent-background background-white radius-75 py-3"
            bsPrefix=" "
          >
            {items.map((item, index) => (
              <FaqAccordionItem
                eventKey={`${index + 1}`}
                subtitle={item.subtitle}
                title={item.title}
                contentHtml={item.contentHtml}
              />
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  )
}
