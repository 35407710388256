import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import starOrange from "../../assets/icons/star_orange.webp"
import { useAppDispatch } from "../../app/hooks"
import { setProductModal } from "../modals/modalsSlice"
import {
  capitalizeFirstLetter,
  formatNumber,
  toRatingText,
} from "../../utils/stringUtils"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { ProductSchema } from "../schemas/ProductSchema"
import { Shop } from "../../models/base/shop.interface"
import { useGetCouponsQuery } from "../../app/services/base/coupons"
import tagWhite from "../../assets/icons/tag_white.webp"
import ticketWhite from "../../assets/icons/ticket_white.webp"

interface ProductCardProps {
  product: EsaScraperProduct
  hideSchema?: boolean
  hideCol?: boolean
  customItemClass?: string
  shop?: Shop | undefined
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  hideSchema,
  hideCol,
  customItemClass,
  shop,
}) => {
  const [imgProduct, setImgProduct] = useState<string>(product.cdn_image_url)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { data: couponsWithShops } = useGetCouponsQuery({
    domain: product.domain,
    page: 1,
    perPage: 1,
  })
  const infoCoupon = product.coupon
    ? `${formatNumber(Number(product.coupon?.discount), 0)}${
        product.coupon.discount_type == 1 ? "%" : "€"
      }`
    : undefined

  const productName = capitalizeFirstLetter(
    product.product_name
      ? product.product_name
      : product.title
      ? product.title
      : "",
  )
  const detailsUrl = `/${
    product && product.product_details_enabled && product.plan >= 20
      ? "prodotti-eshop"
      : "products"
  }/${product.domain}/${product.product_name}/q/${product.link_domain_id}`

  function handleDetailsClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) {
    if ((shop && shop.plan !== "company") || product.plan < 20) {
      e.preventDefault()
      dispatch(setProductModal(product))
    } else {
      navigate(detailsUrl)
    }
  }

  useEffect(() => {
    if (product.cdn_image_url) {
      setImgProduct(product.cdn_image_url)
    }
  }, [product])

  return (
    <>
      <div
        className={`${
          hideCol ? "w-100" : "col-12 col-md-6 col-lg-3 col-xl-2 margin-b24-16"
        }`}
      >
        <div
          className={`item-result transparent-card border custom-rounded ${
            customItemClass ? customItemClass : ""
          }`}
        >
          {product.coupon && infoCoupon && (
            <figure className="badge background-gradient-orange w-fit card-item-discount font-14 m-0">
              <img
                src={product.coupon.discount_type == 1 ? ticketWhite : tagWhite}
                className="ticket me-1 img-fluid height-20 width-auto d-none d-lg-inline-block"
                alt={`${shop?.domain} - Coupon sconto da ${infoCoupon}`}
              />
              -{infoCoupon}
            </figure>
          )}
          <div className={`card-body-prodotto`}>
            <div className="card-img-top d-flex justify-content-center align-items-center pt-2 px-1">
              <a
                onClick={handleDetailsClick}
                rel={product.product_details_enabled ? "" : "nofollow"}
              >
                <span className="d-flex w-auto height-200">
                  <img
                    src={imgProduct}
                    className="p-2 img-fluid w-auto m-auto max-height-200"
                    alt={productName}
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = product.image_url!)}
                  />
                </span>
              </a>
            </div>
            {product.plan >= 5 && (
              <div className="d-flex">
                <span className="badge font-14 background-gradient-orange white-color py-1 px-2 box-shadow product-card-tag">
                  Consigliato
                </span>
              </div>
            )}
            <div className={`card-body pb-2`}>
              <a
                onClick={handleDetailsClick}
                className="gray-text mb-3 font-16 text-decoration-none font-bolder line-clamp line-clamp-2 min-height-50"
                rel={product.product_details_enabled ? "" : "nofollow"}
              >
                {productName}
              </a>
              <div className="d-md-block my-2">
                <span className="font-bolder dark-color">
                  {" "}
                  {`${formatNumber(
                    product.price
                      ? product.price
                      : product.high_price
                      ? product.high_price
                      : 0,
                    2,
                  )} €`}{" "}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between my-2">
                <Link
                  className="simple-orange-link font-14 font-light line-clamp"
                  to={`/it/eshop/${product.domain}`}
                >
                  {product.domain}
                </Link>
              </div>
              <div className="d-flex align-items-center my-2">
                <span className="rating-number-eshop fw-semibold justify-content-left align-items-center">
                  <img
                    src={starOrange}
                    className="star mb-1"
                    alt="Punteggio recensioni"
                    loading="lazy"
                    style={{display: "inline",width: "16px",height: "auto" }}
                  />
                </span>
                <span className="ms-1 font-16 simple-orange-txt">
                  {" "}
                  <b> {formatNumber(product.score, 1)} </b>{" "}
                </span>
                <span className="dark-color ms-1 font-16">
                  {" "}
                  <b>
                    {" "}
                    {capitalizeFirstLetter(toRatingText(product.score))}{" "}
                  </b>{" "}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-end my-2">
                <span className="gray-text font-14 font-light">
                  {product.reviews}{" "}
                  {product.reviews == 1 ? "Recensione" : "Recensioni"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!hideSchema && (
        <ProductSchema
          product={product}
          isAffiliated={shop?.eshopData.structured_data.urlAff ? true : false}
        />
      )}
    </>
  )
}
